import React from 'react';
import Icon from '../icon-2';
import classNames from 'classnames';

interface PropTypes {
  children: React.ReactNode;
  onClick: any;
  type?: string;
  style?: any;
  className?: string;
}

const ActionButton: React.FC<PropTypes> = ({
  children,
  type,
  onClick,
  style,
    className
}) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        'relative flex items-center focus:outline-none text-white font-bold text-11 xs:text-13 md:text-base px-2 xs:px-2 md:px-4 py-1 interactive',
        type === 'checked' && 'bg-white border-2 border-brand-500 text-brand-500 cursor-default',
          type === 'secondary' && 'bg-brand-500 border-brand-500',
        type !== 'secondary' && type !== 'checked' ? 'bg-brand-700 border-2 border-brand-700' : '',
          className
      )}
      style={{
        fontFamily: "'DM Sans', sans-serif",
        paddingLeft: type === 'checked' ? '2rem' : undefined,
        ...style
      }}
    >
      <Icon
        style={{ opacity: type === 'checked' ? 1 : 0 }}
        name="checkmark"
        className="ml-1 w-5 h-5 absolute left-0"
      />
      {children}
    </button>
  );
};

export default ActionButton;
