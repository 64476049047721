import { useEffect } from "react";
export default (stateIdentifier: string, state: any) => {
  useEffect(() => {
    const persistableState = { ...state };
    Object.keys(persistableState)
      .filter(
        (key) =>
          persistableState[key] !== null &&
          persistableState[key] !== undefined &&
          persistableState[key].type === "files"
      )
      .forEach((key) => {
        persistableState[key] = {
          type: "files",
          value: persistableState[key].value.filter(
            (file: any) => typeof file === "string"
          ),
        };
      });
    localStorage.setItem(stateIdentifier, JSON.stringify(persistableState));
  }, [stateIdentifier, state]);
};
