import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
} from "react";
import Screen from "../../../../../components/screen";
import { useModuleState } from "../../../state";
import Text from "../../../../../components/text";
import Icon from "../../../../../components/icon-2";
import Action from "../../../../../components/action";
import Checkbox from "../../../../../components/RealCheckbox";
import path from "ramda/src/path";
import last from "ramda/src/last";
import assoc from "ramda/src/assoc";
import classNames from "classnames";
import uuid from "uuid";
import axios from "axios";
import exampleText from "./example";
import qs from "qs";
import Linkify from "react-linkify";

import {
  parseQuestions,
  useMinWidth,
  getScreenOptions,
  copyToClipboard,
} from "../../../../../utils";
import Button from "../../../../../components/action-button";
import Radio from "../../../../../components/RealRadio";
import declineOptions from "../view-decline/decline-options";
import config from "../../../../../config";
import {useMatomo} from "@datapunt/matomo-tracker-react";

const upload = async (file, uuid, optionId) => {
  const {url, put} = await fetch(
      config.flows["slide-room"].apiBaseUrl + `/getUploadUrl?fileId=${optionId}_${uuid}&fileType=${last(file.name.split("."))}`
  ).then((res) => res.json());
  await axios.put(put, file, {
    onUploadProgress: console.log,
  });
  return url;
};

export default () => {
  const {
    data: {
      OptionId,
      Optionsbeschreibung: OptionsbeschreibungString,
      OptionVon,
      OptionBis,
      Kontrollfragen: questionString,
      optionState,
    },
    state,
    submitAvailabilityFeedback,
    pushStack,
  } = useModuleState();
  const [{ variant }] = useState(getScreenOptions(state));
  const fileRef = useRef<any>({});
  const desktop = useMinWidth(768);
  const [selection, setSelection] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadingFiles, setUploadingFiles] = useState<any>([]);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const { erstellen: createModeParameter } = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const createMode = typeof createModeParameter !== "undefined";
  const [questionStringInput, setQuestionStringInput] = useState("");

  const { trackPageView, trackEvent } = useMatomo();
  useEffect(() => {
    trackPageView({documentTitle: 'Verfuegbarkeit Antworten',})
  }, [trackPageView]);


  // make a list of date between given from/to values
  // @ts-ignore
  const getDaysArray = function (s, e) {
    for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
      a.push(new Date(d));
    }
    return a;
  };

  // make standart Date out of german formasted date dd.mm.YYYY
  const parseDate = (input) => {
    let parts = input.match(/(\d+)/g);
    // note parts[1]-1
    return new Date(parts[2], parts[1] - 1, parts[0]);
  };
  const daylist =
    OptionBis && OptionVon
      ? getDaysArray(
          new Date(parseDate(OptionVon)),
          new Date(parseDate(OptionBis))
        )
      : [];
  const dateOptions = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const [availabilityDates, setAvailabilityDates] = useState([]);

  const checkAndRemove = (array, value) => {
    const index = array.indexOf(value);
    if (index > -1) {
      array.splice(index, 1);
    }
  };

  const countDatesValues = (array, value) => {
    let counter = 0;
    for (let i = 0; i < array.length; i++) {
      if (array[i].includes(value)) counter++;
    }

    return counter;
  };

  const handleDates = (e) => {
    const dates = [...availabilityDates];
    const { name, value } = e.target;

    if (value === "VIELLEICHT" || value === "NEIN") {
      checkAndRemove(dates, `${name}: NEIN`);
      checkAndRemove(dates, `${name}: VIELLEICHT`);
      // @ts-ignore
      dates.push(`${name}: ${value}`);
    } else {
      checkAndRemove(dates, `${name}: NEIN`);
      checkAndRemove(dates, `${name}: VIELLEICHT`);
    }
    setAvailabilityDates(dates);
  };

  const allQuestions = parseQuestions(
    createMode ? questionStringInput : questionString
  );

  //const testLink = OptionsbeschreibungString+"\n\n#Externer Link\n https://dbps.bizpin.de/invite/cf8e00e1-3611-4e96-b265-ba88838c43e6";
  // const externalLink = testLink.split('#Externer Link\n');

  let externalLink = OptionsbeschreibungString.split("# Externer Link\n")[1];
  const pattern = /^((http|https):\/\/)/;
  if (externalLink && !pattern.test(externalLink)) {
    externalLink = "https://" + externalLink;
  }

  const [externalLinkApproved, setExternalLinkApproved] = useState(
    externalLink === undefined
  );
  const [externalLinkHeight, setExternalLinkHeight] = useState(0);

  const questions = createMode
    ? allQuestions
    : variant === "MAYBE"
    ? allQuestions.filter((q) => path(["precheck"], q))
    : optionState === "second"
    ? allQuestions.filter((q) => !path(["precheck"], q))
    : allQuestions;

  useEffect(() => {
    setErrorMessage("");
  }, [selection, setErrorMessage]);

  useEffect(() => {
    const uploadQuestions = questions
      .map((q: any, index: any) => (q.type === "upload" ? index : undefined))
      .filter((x) => typeof x !== "undefined");
    uploadQuestions.forEach((i) => {
      if (!selection[String(i)]) {
        return;
      }
      selection[String(i)].forEach(async (item, index) => {
        if (typeof item === "string") {
          return;
        }
        if (uploadingFiles.includes(item)) {
          return;
        }
        setUploadingFiles((u) => [...u, item]);
        // upload, then remove from uploadingFiles again
        await upload(item, uuid(), OptionId).then((url) => {
          setUploadingFiles((u) => u.filter((x) => x !== item));
          setSelection((s) => ({
            ...s,
            [String(i)]: s[String(i)].map((x: any, y: any) =>
              index === y ? url : x
            ),
          }));
        });
      });
    });
  }, [questions, selection, setSelection, uploadingFiles, setUploadingFiles]);

  const submit = useCallback(async () => {
    if (submitted) {
      return;
    }

    const dates = availabilityDates.toString();

    // default type
    let typeN = "FIRST_OPTION";
    let variantN = "ACTOR_CONFIRMED";
    let absageStatus, label;
    // when all values are VIELLEICHT
    // console.log("submit 1", selection);
    if (countDatesValues(availabilityDates, "VIELLEICHT") === daylist.length) {
      typeN = "SECOND_OPTION";
      variantN = "ACTOR_MAYBE";
      // console.log("submit VIELLEICHT", typeN, selection);
    }
    // when all values are NEIN
    if (countDatesValues(availabilityDates, "NEIN") === daylist.length) {
      // console.log("NEIN", selection);
      typeN = "DECLINED";
      variantN = "ACTOR_DECLINED";
      absageStatus = "keine Zeit";
      label = "keine Zeit";
    }
    // console.log("submit 2", typeN,variantN, countDatesValues(availabilityDates, "VIELLEICHT"),daylist.length, availabilityDates );
    await submitAvailabilityFeedback({
      type: typeN,
      arguments: {
        questions,
        selection: { ...selection, absageStatus, label },
        dates,
        declineOptions,
      },
    });
    // console.log("after", selection);
    setSubmitted(true);
    return pushStack({
      type: "ViewMessage",
      options: { variant: variantN },
    });
  }, [
    availabilityDates,
    daylist.length,
    pushStack,
    questions,
    selection,
    submitAvailabilityFeedback,
    submitted,
  ]);

  // @ts-ignore
  return (
    <Screen
      containerStyle={{
        paddingBottom: desktop ? 0 : undefined,
      }}
    >
      <Text type="st">
        {variant === "MAYBE"
          ? "Eventuell verfügbar"
          : "Verfügbarkeit bestätigen"}
      </Text>
      {createMode && (
        <div className="relative">
          <textarea
            autoFocus
            className={
              "focus:outline-none resize-none pt-3 pb-12 pl-4 w-full text-brand-500 my-2 pr-4 mt-8"
            }
            placeholder={"Kontrollfragen eintragen"}
            value={questionStringInput}
            onChange={(e) => {
              e.persist();
              setQuestionStringInput(e.target.value);
            }}
            rows={15}
            style={{
              WebkitAppearance: "none",
              fontFamily: "'DM Sans', sans-serif",
              boxShadow: "rgba(184,184,184, 1) 0px 0px 10px 0px",
            }}
          />
          <div className="absolute bottom-0 right-0 mb-5 mr-1">
            <button
              className="text-xs bg-brand-500 text-white py-1 px-3 mr-2 interactive focus:outline-none"
              onClick={() => setQuestionStringInput(exampleText)}
            >
              Beispiel anzeigen
            </button>
            <button
              className="text-xs bg-brand-700 text-white py-1 px-3 interactive focus:outline-none"
              onClick={() => copyToClipboard(questionStringInput)}
            >
              Kopieren
            </button>
          </div>
        </div>
      )}
      {createMode && (
        <div className={classNames("text-xs", questions.length ? "" : "pb-20")}>
          {showRules && (
            <b className="text-sm mr-2">
              Hilfe bei der Erstellung von Kontrollfragen
            </b>
          )}
          <button
            className="focus:outline-none underline inline"
            onClick={() => setShowRules(!showRules)}
          >
            {showRules ? "Hilfe schließen" : "Hilfe anzeigen"}
          </button>
          {showRules && (
            <div className="mt-2">
              <ul className="list-disc ml-4">
                <b className="-ml-4 mt-2">Allgemein</b>
                <li>
                  Antwortoptionen in eckigen Klammern nach der Frage eintragen
                  (z.B. [Ja])
                </li>
                <li>
                  Mehrere Antwortoptionen durch Semikolon trennen (z.B.
                  [Castingtermin gebucht; Castingtermin noch nicht gebucht])
                </li>
                <li>
                  Mit jeder Antwortmöglichkeit muss die Anfrage beantwortet
                  werden können. "Ich kann nicht" wäre zum Beispiel keine
                  gültige Antwortmöglichkeit, um die erste oder zweite Option zu
                  geben.
                </li>
                <li>
                  Um eine Kontrollfrage schon bei der 2. Option abzufragen, ! in
                  die eckigen Klammern mit eintragen (z.B. Mit der Kompensation
                  bin ich einverstanden [!Ja])
                </li>
                <br />
                <b className="-ml-4">Weitere Möglichkeiten</b>
                <li>
                  * verwenden, um Satzteile <b>hervorzuheben</b> (z.B. Ich bin
                  am * 17.8.2020* verfügbar)
                </li>
                <li>
                  [upload] hinter der Frage eintragen, um bei einer
                  Kontrollfrage den <b>Dateiupload</b> zu verwenden (z.B. bei
                  Polas)
                </li>
                <li>
                  Für <b>Freitext-Eingabe</b> das Schlüsselwort "freitext" zu
                  einer Antwortoption beifügen (z.B. [Ja; Ja mit Bemerkung -
                  freitext]
                </li>
              </ul>
            </div>
          )}
        </div>
      )}
      {createMode && !!questions.length && (
        <Text type="sst" style={{ paddingBottom: 0 }}>
          Vorschau der Kontrollfragen
        </Text>
      )}

      {questions.map((item: any, index: number) => {
        if (!item) {
          return null;
        }
        const { label, type, options } = item;
        const allOptions = [{ label: "Antwort wählen" }, ...options];
        return (
          <div className="my-2 my-8" key={index}>
            <div className="md:flex md:items-center md:w-full">
              <div className="text-brand-500 font-semibold tracking-wide flex-grow mr-4">
                {label}
              </div>
              <div className="flex-shrink-0 relative interactive">
                {type === "dropdown" && (
                  <select
                    className="absolute top-0 h-full w-full opacity-0"
                    onChange={(e) => {
                      setSelection({
                        ...selection,
                        [String(index)]:
                          e.target.value === "0"
                            ? undefined
                            : {
                                index: e.target.value,
                                textInput: allOptions[+e.target.value].freeText
                                  ? ""
                                  : undefined,
                              },
                      });
                    }}
                  >
                    {allOptions.map((option: any, i: number) => (
                      <option key={i} value={i}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                )}
                {type === "upload" && (
                  <input
                    multiple
                    ref={fileRef}
                    onChange={() => {
                      const files = Array.from(fileRef.current.files);
                      if (!files.length) {
                        return;
                      }
                      const hasTooBigFiles = files.some((f: any) => {
                        const size = f.size;
                        return size > 5000 * 1000000;
                      });
                      if (hasTooBigFiles) {
                        return alert(
                          `Eine oder mehrere Dateien sind zu groß. Maximalgröße: 5GB.`
                        );
                      }
                      setSelection(assoc(index, files, selection));
                    }}
                    type="file"
                    className="hidden absolute top-0 left-0 opacity-0 cursor-pointer z-10 w-full h-full"
                  />
                )}
                <button
                  onClick={() => {
                    if (createMode) {
                      return;
                    }
                    if (type === "dropdown") {
                      return;
                    }
                    fileRef.current.click();
                  }}
                  className={classNames(
                    "flex items-center bg-gray-100 text-brand-500 md:text-white md:bg-brand-500 text-white w-48 mt-2 py-2 px-4 md:mt-0 focus:outline-none cursor-pointer",
                    type === "dropdown" ? "" : ""
                  )}
                >
                  <div className="flex-grow font-semibold tracking-wide text-left">
                    {type === "upload"
                      ? selection[index]
                        ? !!selection[index].find((x) => typeof x !== "string")
                          ? "Lade hoch..."
                          : `${selection[index].length} ${
                              selection[index].length === 1
                                ? "Datei"
                                : "Dateien"
                            }`
                        : "Dateien wählen"
                      : selection[String(index)]
                      ? allOptions[selection[String(index)].index].label
                      : "Antwort wählen"}
                  </div>{" "}
                  <Icon
                    name={type === "dropdown" ? "down" : "upload"}
                    className={classNames(
                      "flex-shrink-0",
                      type === "dropdown" ? "h-6 w-6" : "w-4 h-4"
                    )}
                  />
                </button>
              </div>
            </div>
            {questions[index].type === "dropdown" &&
              !!selection[String(index)] &&
              allOptions[+selection[String(index)].index].freeText && (
                <textarea
                  autoFocus
                  className={
                    "focus:outline-none resize-none py-3 pl-4 w-full text-brand-500 my-2 pr-4 mt-8"
                  }
                  placeholder={"Zusätzliche Bemerkung eintragen"}
                  value={selection[String(index)].textInput}
                  onChange={(e) => {
                    e.persist();
                    setSelection({
                      ...selection,
                      [String(index)]: {
                        ...selection[String(index)],
                        textInput: e.target.value,
                      },
                    });
                  }}
                  rows={desktop ? 2 : 4}
                  style={{
                    WebkitAppearance: "none",
                    fontFamily: "'DM Sans', sans-serif",
                    boxShadow: "rgba(184,184,184, 1) 0px 0px 10px 0px",
                  }}
                />
              )}
          </div>
        );
      })}
      {daylist.length > 0 && (
        <form className="my-8">
          <div className="grid grid-cols-5">
            <div className="text-white bg-brand-500 font-semibold p-1 col-span-2">
              Verfügbar an Datum
            </div>
            <div className="text-white bg-brand-500 font-semibold  p-1 text-right">
              Ja
            </div>
            <div className="text-white bg-brand-500 font-semibold  p-1 text-right">
              Vielleicht
            </div>
            <div className="text-white bg-brand-500 font-semibold  p-1 text-right">
              Nein
            </div>

            {daylist.map((v: any, index: number) => {
              // @ts-ignore
              const label: string = v.toLocaleDateString("de-DE", dateOptions);
              // @ts-ignore
              const radioYES =
                availabilityDates.indexOf(`${label}: NEIN`) === -1 &&
                availabilityDates.indexOf(`${label}: VIELLEICHT`) === -1;
              // @ts-ignore
              const radioMaybe =
                availabilityDates.indexOf(`${label}: VIELLEICHT`) > -1;
              // @ts-ignore
              const radioNo = availabilityDates.indexOf(`${label}: NEIN`) > -1;
              return (
                <Fragment key={index}>
                  <div className="text-brand-500 font-semibold tracking-wide flex-grow p-1 pr-2 col-span-2">
                    {label}
                  </div>

                  <Radio
                    description=""
                    hideDefaultBorder
                    isChecked={radioYES}
                    name={label}
                    setIsChecked={handleDates}
                    isSmall
                    className="xs:p-1"
                    value="JA"
                  />
                  <Radio
                    description=""
                    hideDefaultBorder
                    isChecked={radioMaybe}
                    name={label}
                    setIsChecked={handleDates}
                    isSmall
                    className="xs:p-1"
                    value="VIELLEICHT"
                  />
                  <Radio
                    description=""
                    hideDefaultBorder
                    isChecked={radioNo}
                    name={label}
                    setIsChecked={handleDates}
                    isSmall
                    className="xs:p-1"
                    value="NEIN"
                  />
                </Fragment>
              );
            })}
          </div>
        </form>
      )}

      {externalLink && (
        <div className="my-2 my-8 md:my-16 flex flex-col">
          <Button
            type="secondary"
            className="mt-4 self-end py-2"
            onClick={() =>
              setExternalLinkHeight(externalLinkHeight === 0 ? 400 : 0)
            }
          >
            E-Casting Infos anzeigen
          </Button>
          <iframe
            title="externalLink"
            src={externalLink.trim()}
            style={{
              height: externalLinkHeight,
              overflow: "hidden",
              overflowX: "auto",
              width: "100%",
              margin: "2rem 0",
            }}
          />
          <p
            className={classNames(
              "my-2",
              externalLinkHeight > 0 ? "block" : "hidden"
            )}
          >
            Externen Link auf neuer Seite öffen:&nbsp;
            <Linkify
              properties={{
                target: "_blank",
                style: { textDecoration: "underline" },
              }}
              className="text-brand-700"
            >
              {externalLink.trim()}
            </Linkify>
          </p>
          <Checkbox
            description="Ich habe oben stehende E-casting Infos gelesen und werde ein E-casting einreichen"
            isChecked={externalLinkApproved}
            setIsChecked={() => setExternalLinkApproved(!externalLinkApproved)}
            className={classNames(
              "",
              externalLinkHeight > 0 ? "block" : "hidden"
            )}
          />
        </div>
      )}

      <Action
        inline={variant !== "MAYBE"}
        errorMessage={errorMessage}
        buttons={
          createMode
            ? []
            : [
                {
                  disabled: !externalLinkApproved,
                  onClick: async () => {
                    if (
                      Object.keys(selection).length !== questions.length ||
                      Object.values(selection).find((s: any) => s.index === "0")
                    ) {
                      return setErrorMessage(
                        "Fragen beantworten um fortzufahren"
                      );
                    }
                    const missingCount = Object.values(selection).filter(
                      (s: any) =>
                        typeof s.textInput === "string" && s.textInput === ""
                    ).length;
                    if (missingCount) {
                      return setErrorMessage(
                        `${
                          missingCount > 1 ? "Bemerkungen" : "Bemerkung"
                        } eintragen um fortzufahren`
                      );
                    }
                    if (variant !== "MAYBE") {
                      // check if upload done, if not setreadytosubmit
                      // if yes, submit
                      const stillUploading = !!questions.find((q, i) =>
                        q.type === "upload"
                          ? !!selection[i].find((x) => typeof x !== "string")
                          : false
                      );
                      if (stillUploading) {
                        return setReadyToSubmit(true);
                      }
                      return submit();
                    }
                    trackEvent({ category: 'verfuegbarkeit-click', action: "Anfrage beantwortet" });
                    pushStack({
                      type: "ViewSelectFollowupDate",
                      options: { questions, selection },
                    });
                  },
                  children: readyToSubmit
                    ? "Lade..."
                    : "Verfügbarkeit bestätigen",
                },
              ]
        }
      />
    </Screen>
  );
};
