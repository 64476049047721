import React from 'react';
import classNames from 'classnames';

import Text from '../text';

interface PropTypes {
  onClick?: any;
  buttons?: any;
  children?: any;
  errorMessage?: any;
  id?: any;
  style?: any;
  wrapperStyle?: any;
  modal?: any;
  description?: any;
  inline?: any;
  disabled?: boolean;
}

const Component: React.FC<PropTypes> = ({
  onClick,
  buttons,
  inline,
  children,
  errorMessage,
  id,
  style,
  wrapperStyle,
  modal,
  description
}) => {

  return (
    <div
      className={classNames(
        'w-full z-10 flex justify-center flex-col items-center bg-white',
        inline
          ? 'pt-8 pb-4 md:pb-8'
          : 'pt-4 pb-4 md:pb-8 fixed md:absolute bottom-0 z-50 left-0 border-t border-brand-300 md:border-none'
      )}
      style={wrapperStyle}
    >
      {!!errorMessage && (
        <Text type="dtc" className="mb-2">
          {errorMessage}
        </Text>
      )}
      <div
        className={classNames(
          'w-full lg:w-3/4 flex flex-col items-center justify-center',
          inline ? '' : 'px-10 md:px-0'
        )}
        style={{
          maxWidth: 373
        }}
      >
        {(onClick
          ? ([{ onClick, style, children, id }] as any)
          : (buttons as any).filter(x => x)
        ).map((item, index) => {
          return(
          <button
              disabled={item.disabled}
            key={index}
            id={item.id}
            onClick={item.onClick}
            className={classNames(
              'w-full h-12 text-white focus:outline-none font-bold tracking-wider interactive my-2',
              index === 0 && !item.secondary ? 'bg-brand-700' : 'bg-brand-500',
              index === 0 && !item.secondary
                ? 'procast-primary-cta'
                : 'procast-secondary-cta',
              inline ? '' : 'md:mx-32',
                item.disabled ? 'opacity-50 ' : ''
            )}
            style={item.style}
          >
            <Text type="cta">{item.children}</Text>
          </button>
        )})}
      </div>
      <Text
        type="dtc"
        className={classNames(
          'mt-2',
          modal ? 'mx-5 xs:mx-10' : 'md:mx-32 mx-10'
        )}
        style={{
          maxWidth: modal ? undefined : 650,
          width: modal ? '100%' : undefined
        }}
      >
        {description}
      </Text>
    </div>
  );
};
export default Component;
