import React from 'react';

interface PropTypes {
  name: string;
  className?: string;
  [key: string]: any;
}

const Icon: React.FC<PropTypes> = ({ name, className, ...restProps }) => (
  <svg {...restProps} className={className}>
    <use xlinkHref={`#icon-${name}`} />
  </svg>
);

export default Icon;
