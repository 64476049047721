import React, { memo } from "react";
import classNames from "classnames";
import * as R from "ramda";
import { getButtonParameters, useCachedSrc } from "../../utils";
import { useMatomo } from "@datapunt/matomo-tracker-react";

function Footer({
  item,
  setOptionActorStatus,
}: //showModal,
{
  item: any;
  setOptionActorStatus: any;
  // showModal: any;
}) {
  const { trackEvent } = useMatomo();

  // already booked
  if (item["BuchenStatus"] === "Ja") {
    return null;
  }

  const { label } = getButtonParameters(item);

  const isRequestBtn =
    !item.AngefragtStatus &&
    item.Status !== "1. Option" &&
    item.Status !== "1. Option(Siehe Bemerkung!)" &&
    item.Status !== "2. Option" &&
    item.Status !== "Absage" &&
    item.Status !== "keine Zeit" &&
    item.Status !== "kein Interesse";

  const isUnRequestBtn =
    item.AngefragtStatus &&
    item.Status !== "1. Option" &&
    item.Status !== "1. Option(Siehe Bemerkung!)" &&
    item.Status !== "2. Option" &&
    item.Status !== "Absage" &&
    item.Status !== "keine Zeit" &&
    item.Status !== "kein Interesse";

  return (
    <div className="absolute bottom-0 left-0 w-full items-center flex flex-row flex-wrap items-stretch">
      {(isUnRequestBtn || isRequestBtn) && (
        <button
          onClick={() => {
            if (isUnRequestBtn) {
              trackEvent({
                category: "diaraum-darsteller",
                action: `UNREQUEST ${item.IDNummer}`,
              });
              setOptionActorStatus("UNREQUEST", item.IDNummer);
              setOptionActorStatus(
                item["Favoriten-Status"] ? "UNFAVORITE" : "FAVORITE",
                item.IDNummer
              );
            } else {
              trackEvent({
                category: "diaraum-darsteller",
                action: `REQUEST ${item.IDNummer}`,
              });
              setOptionActorStatus("REQUEST", item.IDNummer);
              setOptionActorStatus(
                item["Favoriten-Status"] ? "UNFAVORITE" : "FAVORITE",
                item.IDNummer
              );
            }
          }}
          className="h-10 font-bold text-sm flex-grow justify-center items-center flex text-white interactive bg-brand-500"
        >
          {isUnRequestBtn ? "Anfrage -" : "Anfragen"}
        </button>
      )}

      <button
        onClick={() => {
          trackEvent({
            category: "diaraum-darsteller",
            action: `${item["Favoriten-Status"] ? "UNFAVORITE" : "FAVORITE"} ${
              item.IDNummer
            }`,
          });
          setOptionActorStatus(
            item["Favoriten-Status"] ? "UNFAVORITE" : "FAVORITE",
            item.IDNummer
          );
        }}
        disabled={item["BuchenStatus"] === "Ja"}
        className={classNames(
          "h-10 font-bold text-sm ml-1px flex-grow justify-center items-center flex text-white interactive bg-brand-500"
        )}
      >
        Auswahl {item["Favoriten-Status"] ? "-" : "+"}
      </button>

      {label === "Angefragt" && (
        <div className="h-8 font-bold text-sm bg-brand-300 w-full justify-center mt-1px items-center flex text-gray-500 pointer-events-none">
          Angefragt
        </div>
      )}
    </div>
  );
}

const PersonImage = memo(function (props: any) {
  const { src, title, className } = props;
  // use cached image
  const cachedSrc = useCachedSrc(src);
  return (
    <img
      src={cachedSrc}
      className={classNames("w-full", className)}
      alt={title}
      loading="lazy"
    />
  );
});

export default function PersonCard({
  index,
  item,
  columns,
  onClick,
  setOptionActorStatus,
  gridCols,
}: {
  index: any;
  item: any;
  columns: any;
  onClick: any;
  setOptionActorStatus: any;
  showModal?: any;
  gridCols: any;
}) {
  return (
    <div
      className={classNames(
        "personCard relative listViewItem showFavoritedOnHover",
        !gridCols && "listViewItemDefault mb-8"
      )}
      style={{
        marginBottom:
          columns === 1 && index !== 0
            ? "2rem"
            : columns === 1 && index === 0
            ? "1rem"
            : undefined,
      }}
    >
      <button
        onClick={
          onClick ? () => onClick(item.IDNummer, item.SedcardJWT) : undefined
        }
        key={String(index)}
        className="overflow-hidden interactiveLight focus:outline-none w-full h-full previewButton"
        style={{
          boxShadow: "rgba(187, 187, 187, 100) 0px 2px 4px 0px",
        }}
      >
        <PersonImage
          src={
            item.Preview ||
            R.pathOr(item.imageUrl, ["BilderTop5", 0, "path"], item)
          }
          title={item?.Name.toLowerCase()}
        />
        {item.Preview2 && (
          <PersonImage
            src={
              item.Preview2 ||
              R.pathOr(item.imageUrl, ["BilderTop5", 1, "path"], item)
            }
            title={item?.Name.toLowerCase()}
            className="absolute top-0 left-0 preview2"
          />
        )}
        <div className="actorsName absolute top-0 left-0 mt-1 ml-2 text-white font-bold text-sm">
          {item.Name.toLowerCase()}
        </div>
      </button>

      <Footer item={item} setOptionActorStatus={setOptionActorStatus} />
    </div>
  );
}
