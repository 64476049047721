import React, { useEffect } from "react";
import classNames from "classnames";
import Linkify from "react-linkify";

const getFormatted = (str: string, className = "") =>
  str.split(/[\r\n]+/).map((line: string, i: React.Key) => {
    const splittedLine = line.split("\t");
    return (
      <div key={i} className={classNames("procast-linkified", className)}>
        <Linkify
          properties={{
            target: "_blank",
            style: { textDecoration: "underline" },
          }}
        >
          {splittedLine.map((x: any, i2: any) => {
            if (
              x.endsWith("jpeg") ||
              x.endsWith("jpg") ||
              x.endsWith("JPEG") ||
              x.endsWith("JPG")
            ) {
              return (
                <div
                  key={i2}
                  style={{
                    width: "23%",
                    marginRight: "2%",
                    height: "auto",
                    maxHeight: 100,
                  }}
                >
                  <img
                          src={x}
                          alt="Bild"
                          style={{width: "100%", height: 'auto'}}
                          loading="lazy"
                          className="mb-1 border-brand-300 border inline-block"
                      />
                  </div>
                );
              } else {
              return (
                <span key={i2} className="mr-1 mb-1">
                  {x}
                  {splittedLine.length - 1 !== i2 ? <>&emsp;</> : ""}
                </span>
              );
            }
          })}
        </Linkify>
      </div>
    );
  });

export default ({
  data,
  className,
  style,
  forceMobileView,
  isAnswer = false,
}: any) => {
  useEffect(() => {
    document
      .querySelectorAll(".procast-linkified > span.Linkify > span > a")
      .forEach((x: any) => (x.innerText = "Link"));
  });
  return (
    <div
      style={{ fontFamily: "'DM Sans', sans-serif", ...style }}
      className={classNames("text-sm font-bold w-full", className)}
    >
      <table className={forceMobileView ? "hidden" : "hidden md:block w-full"}>
        <tbody>
          {data.map(
            (item: { key: string; value: any }, index: React.Key) => {
              if (item.key !== "Externer Link" || isAnswer) {
                return (
                  <tr key={index}>
                    <td
                      className="text-brand-500 pr-5 align-top"
                      style={{ width: "40%" }}
                    >
                      {getFormatted(item.key)}
                      <br />
                    </td>
                    <td className="text-brand-700 align-top break-words">
                        {getFormatted(item.value, "inline")}
                      <br />
                    </td>
                  </tr>
                );
              }
              return null;
            }
          )}
        </tbody>
      </table>
      <div className={forceMobileView ? "block" : "block md:hidden"}>
        {data.map((item: any, index: number) => (
          <div key={index}>
            <div className="text-brand-500">{getFormatted(item.key)}</div>
            <div className="text-brand-700">
              {getFormatted(item.value, "inline")}
              <br />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
