import React from "react";
import Icon from "../icon-2";
import { useMinWidth } from "../../utils";
import classNames from "classnames";

interface PropTypes {
  onClick: any;
  children: React.ReactNode;
  avatar?: string;
  icon?: string;
  style?: any;
  textStyle?: any;
  wrapperClassNames?: string;
  disabled?: boolean;
}

const SecondaryButton: React.FC<PropTypes> = ({
  onClick,
  children,
  icon,
  avatar,
  style,
  textStyle,
  wrapperClassNames,
  disabled,
}) => {
  const desktop = useMinWidth(650);
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      style={{ height: desktop ? "2.3rem" : "2.19rem", ...style }}
      className={classNames(
        "border-2 focus:outline-none",
        disabled ? "cursor-default" : "interactive cursor-pointer",
        wrapperClassNames
      )}
    >
      <div
        className={classNames(
          "flex relative items-center justify-center",
          disabled ? "" : "interactiveLight",
          icon ? "pl-1 pr-4" : ""
        )}
      >
        {!!avatar && (
          <div
            className="flex-shrink-0"
            style={{
              marginLeft: desktop ? undefined : -2,
              width: desktop ? "2.25rem" : "1.75rem",
              height: desktop ? "2.25rem" : "1.75rem",
            }}
          >
            <img src={avatar} alt="avatar" loading="lazy" />
          </div>
        )}
        {!!icon && (
          <div>
            <Icon
              name={icon}
              style={{
                marginLeft: 8,
                marginTop: -2,
                width: desktop ? "1.875rem" : "1.1rem",
                height: desktop ? "1.875rem" : "1.15rem",
                color: (textStyle || {}).color,
              }}
            />
          </div>
        )}
        <div
          className="px-3 text-13 md:text-lg font-bold text-brand-500 flex-grow text-left"
          style={{ fontFamily: "'DM Sans', sans-serif", ...textStyle }}
        >
          {children}
        </div>
      </div>
    </button>
  );
};

export default SecondaryButton;
