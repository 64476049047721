import { useEffect } from "react";
import { usePrevious } from "../../utils";
import submitStateToUrl from "../../utils/submit";
import { EventEmitter } from "events";

const e = new EventEmitter();

export default (
  flow: any,
  state: { stack: string | any[] },
  loading: any,
  setLoading: (arg0: boolean) => void,
  submitted: any,
  setSubmitted: (arg0: boolean) => void,
  uploadQueue: { idle: () => any; drain: () => void }
) => {
  const previousStack = usePrevious(state.stack);
  const submit = () => {
    const idle = uploadQueue.idle();
    return new Promise<void>((resolve) =>
      idle
        ? resolve()
        : (uploadQueue.drain = () => {
            resolve();
          })
    ).then(() => {
      return e.emit("uploaded");
    });
  };
  const sendAction = (id: any, args?: any) => {
    return submitStateToUrl(state, flow.submissionUrl, id, args);
  };
  useEffect(() => {
    const onUploaded = () => {
      return submitStateToUrl(state, flow.submissionUrl, "SUBMIT").then(() => {
        setSubmitted(true);
      });
    };
    e.addListener("uploaded", onUploaded);
    return () => {
      e.removeAllListeners();
    };
  }, [state, submitted, setSubmitted, flow.submissionUrl]);
  useEffect(() => {
    if (JSON.stringify(previousStack) === JSON.stringify(state.stack)) {
      return;
    }
    setLoading(true);
    submitStateToUrl(
      state,
      flow.submissionUrl,
      state.stack[state.stack.length - 1].type +
        (state.stack.length >= previousStack.length ? "" : "_BACK")
    ).then(() => setLoading(false));
  }, [state, loading, setLoading, flow.submissionUrl, previousStack]);
  return { submit, sendAction };
};
