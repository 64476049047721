import React from 'react';
import classNames from 'classnames';
import Icon from '../icon-2';

interface PropTypes {
  onBack: () => void;
  isVisible?: boolean;
  isCloseIcon?: boolean;
  id?: string;
}

const BackButton: React.FC<PropTypes> = ({
  onBack,
  isVisible,
  isCloseIcon,
  id
}) => {

  return (
    <button
      id={id}
      onClick={onBack}
      className={classNames(
        'focus:outline-none fixed md:absolute py-2 px-2 interactiveLight top-0 z-50 left-0',
        isVisible ? '' : 'hidden',
        'procast-back-button'
      )}
      style={{ top: 80, display:  isVisible ? 'flex' : 'none',alignItems: 'center'}}
    >
      <Icon
        name={isCloseIcon ? 'close' : 'down'}
        className="h-8 w-8 -ml-2 text-brand-700"
        alt={isCloseIcon ? 'Schließen' : 'Zurück'}
        style={{ transform: isCloseIcon ? undefined : 'rotate(90deg)' }}
      />
      {isCloseIcon ? 'Schließen' : 'Zurück'}
    </button>
  );
};

export default BackButton;
