export default {
  id: "slide-room",
  mountPoint: "procast-slideroom-mountpoint",
  initialStack: [{ type: "ViewList" }],
  resetInitialStack: true,
  // Sends to API-Gateway -> SQS-Queue
  submissionUrl: window.location.hostname.includes("dev")
    ? "https://procast-action.dev.api.procast.de/procast-action"
    : "https://procast-action.api.procast.de/procast-action",
  apiBaseUrl: window.location.hostname.includes("procast.de")
    ? window.location.hostname.includes("dev")
      ? "https://api.procast.de"
      : "https://api.procast.de"
    : // : "http://localhost:3001",
      "https://api.procast.de",
};
