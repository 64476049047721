import React from "react";
import classNames from "classnames";

// const checkIcon = "https://d14yk4uwf1xvt9.cloudfront.net/images/check.svg";
const checkIcon2 = "/images/check_icon.svg";

interface PropTypes {
  description: string | undefined;
  isChecked: boolean;
  setIsChecked: any;
  className?: string;
  hideDefaultBorder?: boolean;
  style?: any;
  name?: string;
  isSmall?: boolean;
  value?: string;
}

const Radio: React.FC<PropTypes> = ({
  isChecked,
  hideDefaultBorder,
  setIsChecked,
  description,
  className,
  style,
  isSmall,
  name,
  value,
}) => {
  return (
    <label
      style={style}
      className={classNames(
        "w-full focus:outline-none transition-border p-3 flex flex-row justify-center items-center",
        !hideDefaultBorder
          ? "border border-brand-300"
          : "border border-transparent",
        className
      )}
    >
      <input
        type="radio"
        name={name ? name : description}
        checked={isChecked}
        onChange={setIsChecked}
        className="hidden"
        value={value}
      />
      <div className="text-xs text-left mr-2 text-brand-400 flex-grow">
        {description}
      </div>
      <div>
        <div
          className={classNames(
            "rounded-full border",
            isSmall ? "h-5 w-5 " : "h-8 w-8",
            isChecked ? "border-white" : "border-brand-400"
          )}
          style={{ boxSizing: "border-box" }}
        >
          {isChecked && (
            <img alt="checkbox" src={checkIcon2} className="full-w h-full" />
          )}
        </div>
      </div>
    </label>
  );
};
export default Radio;
