import React, { useRef, useEffect, useState, useCallback } from "react";
import last from "ramda/src/last";
import pathOr from "ramda/src/pathOr";

export const usePrevious = (val) => {
  const ref = useRef(val);
  useEffect(() => {
    ref.current = val;
  });
  return ref.current;
};

export const useMedia = (values, defaultValue, isTabletIncludedWithMobile?) => {
  const mediaQueryLists = [
    isTabletIncludedWithMobile ? "(min-width: 650px)" : "(min-width: 768px)",
    "all",
  ].map((q) => window.matchMedia(q));

  const getValue = useCallback(() => {
    const index = mediaQueryLists.findIndex((mql) => mql.matches);
    return typeof values[index] !== "undefined" ? values[index] : defaultValue;
  }, [defaultValue, mediaQueryLists, values]);

  const [value, setValue] = useState(getValue);

  useEffect(() => {
    const handler = () => setValue(getValue);
    mediaQueryLists.forEach((mql) => mql.addListener(handler));
    return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler));
  }, [getValue, mediaQueryLists]);

  return value;
};

export const useMinWidth = (value) => {
  const [width, setWidth] = useState(window ? window.innerWidth : 0);
  useEffect(() => {
    const onResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [setWidth]);
  return width > value;
};

export const useDimensions = () => {
  const [width, setWidth] = useState(window ? window.innerWidth : 0);
  const [height, setHeight] = useState(window ? window.innerHeight : 0);
  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth !== width) {
        setWidth(window.innerWidth);
      }
      if (window.innerHeight !== height) {
        setHeight(window.innerHeight);
      }
      return () => {
        window.removeEventListener("resize", onResize);
      };
    };
    window.addEventListener("resize", onResize);
  }, [width, height]);
  return [width, height];
};

export const parseFacts = (facts) => {
  const lines = facts ? facts.split(/[\r\n]+/) : [];
  //@ts-ignore
  window.x = facts;
  const data: any = [];
  let newKey = "";
  let valueLines: any = [];
  lines.forEach((line) => {
    if (line.indexOf("#") === 0) {
      if (newKey !== "") {
        data.push({ key: newKey, value: valueLines.join("\n").trim() });
      }
      newKey = line.substr(1).trim();
      valueLines = [];
    } else if (newKey !== "") {
      valueLines.push(line);
    }
  });
  if (newKey !== "") {
    data.push({ key: newKey, value: valueLines.join("\n").trim() });
  }
  return data;
};

export const parseQuestions = (str) => {
  if (str === undefined) {
    return;
  }
  const questions = str
    .split(/[\r\n]+/)
    .filter((l) => l.indexOf("[") !== -1 && l.indexOf("]") !== -1);
  return questions.map((question) => {
    const optionString = question
      .substr(question.indexOf("[") + 1)
      .split("]")
      .join("");
    const optionStrings = optionString.split(";");

    const options = optionStrings.map((optionString) => {
      const [first, second] = optionString
        .split(/[\u202F\u00A0]/)
        .join(" ")
        .split(/ - | – /);
      const label = first.split("!").join("");
      return {
        label,
        freeText: (second || "").toLowerCase().includes("freitext"),
      };
    });

    return {
      plainText: question.substr(0, question.indexOf("[")),
      label: parseStarredAsBold(question.substr(0, question.indexOf("["))),
      precheck: optionString.indexOf("!") === 0,
      type: optionString.includes("upload") ? "upload" : "dropdown",
      options,
    };
  });
};

export const parseStarredAsBold = (str) => {
  if (str.split("*").length < 3 || str.split("*").length % 2 === 0) {
    return str;
  }
  return (
    <span className="">
      {str.split("*").map((substring, index) =>
        index % 2 === 0 ? (
          <span key={index}>{substring}</span>
        ) : (
          <span className="text-brand-700" key={index}>
            {substring}
          </span>
        )
      )}
    </span>
  );
};

export const validateEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getScreenOptions = (state) => {
  const screen = last(state.stack);
  return pathOr({}, ["options"], screen);
};

export const copyToClipboard = (str) => {
  const el = document.createElement("textarea");
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

export const getButtonParameters = (sedcard) => {
  if (sedcard.BuchenStatus === "Ja") {
    return { label: "Gebucht", type: "disabled", permanent: true };
  }
  /*if (sedcard.Status === "1. Option") {
    return { label: "Buchen", type: "enabled", permanent: true };
  }
  if (sedcard.Status === "2. Option") {
    return { label: "Eventuell verfügbar", type: "disabled", permanent: true };
  }
  if (["Absage", "keine Zeit", "kein Interesse"].includes(sedcard.Status)) {
    return { label: "Abgesagt", type: "passive", permanent: true };
  }*/

  if (
    sedcard.AngefragtStatus ||
    sedcard.Status === "1. Option" ||
    sedcard.Status === "1. Option(Siehe Bemerkung!)" ||
    sedcard.Status === "2. Option" ||
    ["Absage", "keine Zeit", "kein Interesse"].includes(sedcard.Status)
  ) {
    return { label: "Angefragt", type: "disabled", permanent: true };
  }
  return {
    label: window.innerWidth < 1025 ? "Anfragen" : "Verfügbarkeit anfragen",
    type: "enabled",
  };
};

export const useCachedSrc = (src) => {
  const [cachedSrc, setCachedSrc] = useState<string>(src);
  useEffect(() => {
    if (cachedSrc && src && cachedSrc.split("?")[0] === src.split("?")[0]) {
      return;
    }
    setCachedSrc(src);
  }, [cachedSrc, src]);
  return cachedSrc;
};
