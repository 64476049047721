import React from "react";
import classNames from "classnames";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import useResizeObserver from "use-resize-observer";

import ProgressBar from "../progress-bar";
import BackButton from "../back-button";
import dissoc from 'ramda/src/dissoc';

import { usePrevious, useMedia } from "../../utils";

interface PropTypes {
  stack: any;
  popStack: () => void;
  pushStack: (screen: any) => void;
  children: React.ReactNode;
  percentage: number;
  isBackButtonVisible: boolean;
  isCloseIconVisible: boolean;
  onDrop?: any;
  wrapperStyle?: any;
}

const ScreenManager: React.FC<PropTypes> = ({
  stack,
  popStack,
  children,
  percentage,
  isBackButtonVisible,
  isCloseIconVisible,
  wrapperStyle,
}) => {
  const screen = stack[stack.length - 1];
  const [ref, , height] = useResizeObserver();
  const isDesktop = useMedia([true, false], false);
  const prevStack = usePrevious(stack);

  return (
    <div
      className={classNames(
        "w-full md:h-screen md:flex md:justify-center",
        height > window.innerHeight ? "md:items-start" : "md:items-center"
      )}
    >
      <div
        ref={ref}
        className={classNames(
          "w-full md:w-5/6 lg:w-10/12 md:relative md:bg-white md:my-12 md:overflow-hidden desktopBoxShadow transition-height"
        )}
        style={{
          maxWidth: 1048,
          height: isDesktop ? undefined : "35rem",
          minHeight: isDesktop ? "35rem" : undefined,
        }}
      >
        <ProgressBar percentage={percentage || 0} />
        <BackButton
          id={screen.type}
          isCloseIcon={isCloseIconVisible}
          isVisible={isBackButtonVisible && stack.length > 1}
          onBack={popStack}
        />
        <SwitchTransition>
          <CSSTransition
            timeout={600}
            classNames={classNames(
              stack.length >= (prevStack.length || 0)
                ? "my-animated-screen"
                : "fadeover-backwards"
            )}
            in={true}
            key={JSON.stringify(dissoc('options', screen))}
          >
            <div className="flex justify-center w-full overflow-hidden">
              <div
                className={classNames(
                  "transition-opacity w-full h-full lg:my-4"
                )}
                style={
                  wrapperStyle || {
                    maxWidth: 650,
                  }
                }
              >
                {children}
              </div>
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
};

export default ScreenManager;
