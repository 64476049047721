import { useContext, useEffect } from "react";
import qs from "qs";
import * as R from "ramda";
import { post } from "../../../utils/request";
import { NotificationsContext } from "../../../config/context/provider/NotificationsProvider";

export default function useRemoteState(flowState) {
  const { setData, updateScreen } = flowState;
  const { setNotifications } = useContext(NotificationsContext);

  useEffect(() => {
    let { id, erstellen } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    if (typeof erstellen === "string") {
      return;
    }
    id = R.last(id) === "/" ? R.dropLast(1, id) : id;
    setNotifications("Lade Casting-Daten");
    post("availability-interface", { id })
      .then((res) => {
        setNotifications();
        if (!res.BuchenStatus) {
          if (
            res.Status === "1. Option" ||
            res.status === "1. Option(Siehe Bemerkung!)"
          ) {
            updateScreen({
              type: "ViewMessage",
              options: { variant: "ACTOR_CONFIRMED" },
            });
          } else if (
            res.Status === "keine Zeit" ||
            res.Status === "kein Interesse"
          ) {
            updateScreen({
              type: "ViewMessage",
              options: { variant: "THANK_YOU" },
            });
          } else if (res.Status === "2. Option") {
            updateScreen({
              type: "ViewMessage",
              options: { variant: "ACTOR_MAYBE" },
            });
          }
        }
        setData((d) => {
          return { ...d, ...res };
        });
      })
      .catch(() => {
        setNotifications(
          "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal."
        );
      });
    // ToDo: Causes Rerender calls to DEATH!!! Do NOT add dependencies  !!
  }, []);
}
