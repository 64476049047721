import React from 'react';
import Container from '../container';
import Text from '../text';
import PrimaryButton from '../primary-button';

interface PropTypes {
  children?: React.ReactNode;
  headline?: string;
  description?: string | React.ReactNode;
  id?: string;
  errorMessage?: string;
  next?: (x: any) => void;
  nextLabel?: string;
  nextDescription?: React.ReactNode | string;
  isFinished?: boolean;
  containerStyle?: any;
}

const Screen: React.FC<PropTypes> = ({
  containerStyle,
  children,
  headline,
  description,
  id,
  errorMessage,
  next,
  nextLabel = 'Weiter',
  nextDescription,
  isFinished
}) => {
  return (
    <Container style={containerStyle}>
      {!!headline && <Text type="st">{headline}</Text>}
      {!!description && (
        <Text type="sst" className="mb-4">
          {description}
        </Text>
      )}
      {children}
      {!!next && (
        <PrimaryButton
          style={isFinished ? { backgroundColor: '#1C355E' } : undefined}
          onClick={next}
          errorMessage={errorMessage}
          id={id}
          description={nextDescription}
        >
          {nextLabel}
        </PrimaryButton>
      )}
    </Container>
  );
};

export default Screen;
