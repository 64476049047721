import React, {useEffect, useState} from 'react';
import Screen from '../../../../../components/screen';
import { useModuleState } from '../../../state';
import Text from '../../../../../components/text';
import Action from '../../../../../components/action';
import LogoWatermark from '../../../../../components/logo-watermark';
import { useMinWidth, getScreenOptions } from '../../../../../utils';
import {useMatomo} from "@datapunt/matomo-tracker-react";

export default () => {
  const { pushStack, state, submitAvailabilityFeedback } = useModuleState();
  const { declineOptions, selection } = getScreenOptions(state);
  const desktop = useMinWidth(768);
  const [reason, setReason] = useState<any>('');
  const [errorMessage, setErrorMessage] = useState('');
    const { trackPageView, trackEvent } = useMatomo();
    useEffect(() => {
        trackPageView({documentTitle: 'Verfuegbarkeit Absage',})
    }, [trackPageView]);

  return (
    <Screen containerStyle={{ paddingBottom: desktop ? 0 : undefined }}>
      <LogoWatermark bottom />

      <Text type="st" className="md:px-8">
        Was ist der Grund für deine Absage?
      </Text>
      <div className="flex justify-center mt-12 mb-20">
        <textarea
          autoFocus
          className={
            'focus:outline-none resize-none py-3 pl-4 w-full text-brand-500 my-2 pr-4 mt-8'
          }
          placeholder={'Kurze Beschreibung des Grundes für deine Absage'}
          value={reason}
          onChange={e => {
            e.persist();
            setReason(e.target.value);
          }}
          rows={4}
          style={{
            maxWidth: 373,
            WebkitAppearance: 'none',
            fontFamily: "'DM Sans', sans-serif",
            boxShadow: 'rgba(184,184,184, 1) 0px 0px 10px 0px'
          }}
        />
      </div>
      <Action
        inline
        errorMessage={errorMessage}
        buttons={[
          {
            onClick: async () => {
              if (!reason.length) {
                return setErrorMessage(
                  'Anderen Grund eintragen um fortzufahren'
                );
              }
              await submitAvailabilityFeedback({
                type: 'DECLINED',
                arguments: {
                  declineOptions,
                  selection,
                  otherReason: reason
                }
              });
                trackEvent({ category: 'verfuegbarkeit-click', action: "Absage" });
              pushStack({
                type: 'ViewMessage',
                options: { variant: 'ACTOR_DECLINED' }
              });
            },
            children: 'Absage bestätigen'
          }
        ]}
      />
    </Screen>
  );
};
