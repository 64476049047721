import React, { useState, useEffect } from 'react';
import Screen from '../../../../../components/screen';
import { useModuleState } from '../../../state';
import Text from '../../../../../components/text';
import classNames from 'classnames';
import Action from '../../../../../components/action';
import LogoWatermark from '../../../../../components/logo-watermark';
import { useMinWidth, getScreenOptions } from '../../../../../utils';
import times from 'ramda/src/times';
import assoc from 'ramda/src/assoc';
import Icon from '../../../../../components/icon-2';

const dayOptions = times(x => x + 1, 31);
const monthOptions = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember'
];
const currentYear = new Date().getFullYear();
const yearOptions = [currentYear, currentYear + 1];
export default () => {
  const { pushStack, state, submitAvailabilityFeedback } = useModuleState();
  const { questions, selection } = getScreenOptions(state);
  const desktop = useMinWidth(768);
  const [date, setDate] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setErrorMessage('');
  }, [date, setErrorMessage]);

  return (
    <Screen containerStyle={{ paddingBottom: desktop ? 0 : undefined }}>
      <LogoWatermark bottom />

      <Text type="st">Eventuell verfügbar</Text>
      <Text type="sst" className="md:mx-32">
        Wann kannst du voraussichtlich eine finale Rückmeldung geben?
      </Text>
      <div className="flex justify-center mt-16 mb-16">
        <div
          className="flex w-full"
          style={{
            maxWidth: 373
          }}
        >
          {['day', 'month', 'year'].map((item, index) => {
            const label =
              item === 'day' ? 'Tag' : item === 'month' ? 'Monat' : 'Jahr';
            const availableOptions = [label].concat(
              item === 'day'
                ? dayOptions
                : item === 'month'
                ? monthOptions
                : yearOptions
            );
            return (
              <div
                key={item}
                className={classNames(
                  'relative w-full',
                  desktop
                    ? 'bg-brand-500 text-white'
                    : 'bg-gray-100 text-brand-500',
                  item === 'day'
                    ? 'border-r border-white'
                    : '',
                  item === 'year'
                    ? 'border-l border-white'
                    : ''
                )}
                style={{
                  fontFamily: "'DM Sans', sans-serif"
                }}
              >
                <select
                  className="absolute top-0 w-full h-full opacity-0"
                  onChange={e => setDate(assoc(index, e.target.value, date))}
                >
                  {availableOptions.map((o, i) => (
                    <option key={i}>{o}</option>
                  ))}
                </select>
                <div className="px-2 py-2 font-semibold text-center flex items-center justify-center">
                  <div className="pr-2">
                    {date[index] ? date[index] : label}
                  </div>
                  <Icon name="down" className="w-4 h-4" />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Action
        errorMessage={errorMessage}
        buttons={[
          {
            onClick: async () => {
              if (!date[0] || !date[1] || !date[2]) {
                return setErrorMessage('Datum eingeben um fortzufahren');
              }
              await submitAvailabilityFeedback({
                type: 'SECOND_OPTION',
                arguments: { followupDate: date, questions, selection }
              });
              pushStack({
                type: 'ViewMessage',
                options: { variant: 'ACTOR_MAYBE' }
              });
            },
            children: 'Bestätigen'
          }
        ]}
      />
    </Screen>
  );
};
