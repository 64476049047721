import React, {useContext} from 'react';
import ScreenManager from '../../components/screen-manager';
import screens from './components/screens';
import Modal from '../../components/modal';
import modals from './components/modals';
import { useModuleState } from './state';
import LogoWatermark from "../../components/logo-watermark";
import SecondaryButton from "../../components/secondary-button";
import {useMinWidth} from "../../utils";
import {NotificationsContext} from "../../config/context/provider/NotificationsProvider";
import DefaultPage from "../default";

export default () => {
  const {
      data: {
          Optionsbeschreibung: OptionsbeschreibungString,
      },
    state: { stack },
    popStack,
    pushStack,
    showModal,
    setModalVisibility
  } = useModuleState();

    const { notifications } = useContext(NotificationsContext);

    if (notifications?.length) {
        return ( <DefaultPage text={notifications} />);
    }

  const screen = stack[stack.length - 1];
  const screenOptions = {
    ...(screens[screen.type].options || {}),
    ...screen.options,
      wrapperStyle: {maXWidth: 800, padding: "0 10% 100px 10%", maxHeight: '90%', }
  };

  let company = "", jobTitle = "";
  if(OptionsbeschreibungString) {
      const options = OptionsbeschreibungString.split(/[\r\n]+/);
      company = options[1].substr(0, 15) + (options[1].length > 15 ? "... | " : " | ");
      jobTitle = options[3].substr(0, 15) + (options[3].length > 15 ? "..." : "");
  }
  const desktop = useMinWidth(650);

  return (
    <div className="relative">
        <header className="listHeader py-2  px-4 md:px-8" style={{minHeight: 40, zIndex: 50}}>
            <div
                className="text-brand-500 text-xl font-bold"
                style={{ fontFamily: "'DM Sans', sans-serif" }}
            >
                { company  + jobTitle }
            </div>
        </header>
      <ScreenManager
        stack={stack}
        popStack={popStack}
        pushStack={pushStack}
        percentage={screenOptions.percentage}
        isBackButtonVisible={screenOptions.hideBackButton ? false : true}
        isCloseIconVisible={screenOptions.showCloseIcon}
        wrapperStyle={screenOptions.wrapperStyle}
      >

        {screens[screen.type].node}
      </ScreenManager>
        <footer className="listFooter">
            <div className="relative" style={{width: 120}}><LogoWatermark relative /></div>

            <div className="flex justify-end">
                <SecondaryButton
                    textStyle={{ color: "white", textAlign: "center" }}
                    style={{
                        backgroundColor: "#C1002B",
                        borderColor: "#C1002B",
                        width: desktop ? "12.7rem" : "8rem",
                    }}
                    onClick={() => showModal("ShowContactInfo")}
                    icon="phone"
                >
                    Kontakt
                </SecondaryButton>
            </div>
        </footer>

      <Modal
        visible={!!screenOptions.modalVisible}
        setVisible={setModalVisibility}
        style={
          modals[screenOptions.modalName]
            ? modals[screenOptions.modalName].style
            : undefined
        }
        preventExit={
          modals[screenOptions.modalName] &&
          modals[screenOptions.modalName].preventExit
        }
      >
        {screenOptions.modalName ? modals[screenOptions.modalName].node : null}
      </Modal>
    </div>
  );
};
