// @ts-ignore TS7053
export default {
  id: "availability-request",
  mountPoint: "procast-availabilityrequest-mountpoint",
  initialStack: [{ type: "ViewRequest" }],
  // Sends to API-Gateway -> SQS-Queue
  submissionUrl:
     window.location.hostname.includes('dev') ? "https://procast-action.dev.api.procast.de/procast-action" : "https://procast-action.api.procast.de/procast-action",
  config: {
    declineOptions: [
      { label: "Bereits anderweitig gebucht" },
      { label: "Bereits privat verplant" },
      { label: "Kompensation nicht ausreichend" },
      { label: "Anderer Grund" },
    ],
  },
};
