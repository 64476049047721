import config from '../config';

export function post(endpoint, parameters) {
  return fetch(config.flows['slide-room'].apiBaseUrl + '/' + endpoint, {
    method: 'POST',
    body: JSON.stringify(parameters),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((r) => r.json());
}
