import React from "react";
import { useModuleState } from "../../state";

export default function ShowConfirmBookingModal() {
  const {
    data: { slideRoom },
  } = useModuleState();

  // fetch sedcard by id
  return (
    <div className="mx-5 my-4">
      <div className="font-bold mx-1">Ihr Booker bei Procast</div>
      <div className="bg-brand-700 text-white p-2 mt-4">
        <div className="font-bold">{slideRoom.Booker}</div>
        <div
          className="interactive cursor-pointer"
          onClick={() =>
            window.open(
              slideRoom.BookerTel
                ? "tel:" + slideRoom.BookerTel.split(" ").join("")
                : "tel:+4940299080",
              "_self"
            )
          }
        >
          Telefon: <u>{slideRoom.BookerTel || "+49 40 299080"}</u>
        </div>
        <div
          className="interactive cursor-pointer"
          onClick={() =>
            window.open(
              slideRoom.BookerMail
                ? "mailto:" + slideRoom.BookerMail
                : "mailto:info@procast.de",
              "_self"
            )
          }
        >
          Email: <u>{slideRoom.BookerMail || "info@procast.de"}</u>
        </div>
      </div>
    </div>
  );
}
