import React from 'react';
import classNames from 'classnames';

interface PropTypes {
  percentage: number;
  style?: any;
}

const ProgressBar: React.FC<PropTypes> = ({ percentage, style }) => {
  return (
    <div
      className={classNames(
        'progressbar bg-brand-500 absolute top-0 left-0 full-w h-1 transition-width'
      )}
      style={{ width: String(percentage * 100) + '%', ...style }}
    />
  );
};

export default ProgressBar;
