import React from 'react';
import classNames from 'classnames';

interface PropTypes {
  children: React.ReactNode;
  type:
    | 'pt'
    | 'ptText'
    | 'wt'
    | 'wd'
    | 'mh'
    | 'st'
    | 'st2'
    | 'sst'
    | 'sstc'
    | 'dtl'
    | 'dtc'
    | 'dtcr'
    | 'cta'
    | 'nt'
    | 'sect'
    | 'fft'
    | 'ct'
    | 'dt'
    | 'il'
    | 'cd'
    | 'lt';
  className?: string;
  style?: any;
}

const font = {
  raleway: "'Raleway', sans-serif",
  dmsans: "'DM Sans', sans-serif",
  dmserif: "'DM Serif Display', serif"
};

const types = {
  pt: {
    // Price Tag
    class: 'font-bold text-lg leading-snug text-center text-white',
    style: {
      fontFamily: font.dmsans
    }
  },
  ptText: {
    // Price Tag Text
    class: 'font-bold leading-tight text-center text-white text-11',
    style: {
      fontFamily: font.dmsans
    }
  },
  st: {
    // Screen Title
    class:
      'font-bold text-2xl leading-none tracking-wide text-left text-brand-700 sm:text-4.5xl sm:text-center',
    style: {
      fontFamily: font.dmsans
    }
  },

  st2: {
    // Screen Title
    class:
      'font-bold text-xl leading-none tracking-wide text-left text-brand-700 sm:text-center',
    style: {
      fontFamily: font.dmsans
    }
  },
  wt: {
    // Welcome Text
    class:
      'font-normal text-2xl leading-tight text-center text-brand-500 sm:text-4.5xl',
    style: { fontFamily: font.dmserif }
  },
  wd: {
    // Welcome description
    class:
      'font-normal leading-snug lg:leading-relaxed text-13 lg:text-lg text-brand-600 text-center pt-4 lg:pt-8 lg:pb-10 md:pt-6 md:pb-8',
    style: { fontFamily: font.dmsans }
  },
  mh: {
    // Modal headline
    class:
      'font-extrabold text-sm md:text-xl leading-tight text-center text-brand-500',
    style: { fontFamily: font.raleway }
  },
  sst: {
    // Screen Subtitle
    class:
      'font-semibold leading-snug text-left text-lg tracking-wide text-brand-500 sm:text-center pt-4 lg:pt-8 lg:pb-10 md:pt-6 md:pb-8',
    style: {
      fontFamily: font.dmsans
    }
  },
  sstc: {
    // Screen Subtitle Centered
    class:
      'font-normal leading-snug text-13 text-brand-600 text-center pt-4 lg:pt-8 lg:pb-10 md:pt-6 md:pb-8',
    style: { fontFamily: font.dmsans }
  },
  dt: {
    // Description text
    class: 'font-normal leading-tight text-brand-400 text-11',
    style: {
      fontFamily: font.dmsans
    }
  },
  dtl: {
    // Description Text Left
    class:
      'font-normal leading-tight text-left text-brand-400 text-11 lg:text-base',
    style: {
      fontFamily: font.dmsans
    }
  },
  dtc: {
    // Description Text Center
    class: 'font-normal leading-tight text-center text-brand-400 text-11',
    style: {
      fontFamily: font.dmsans
    }
  },
  dtcr: {
    // Description Text Center reduced
    class: 'font-bold leading-tight text-center text-gray-300 text-11',
    style: {
      fontFamily: font.dmsans
    }
  },
  il: {
    // Input Label
    class: 'font-normal leading-tight text-center text-brand-400 text-sm',
    style: {
      fontFamily: font.dmsans
    }
  },
  cta: {
    // CTA Text
    class: 'font-extrabold text-xl leading-tight text-center text-white',
    style: {
      fontFamily: font.raleway
    }
  },
  nt: {
    // Number Tag
    class: 'font-normal text-center text-white text-brand-600 text-13',
    style: {
      lineHeight: 1.3,
      fontFamily: font.dmsans
    }
  },
  sect: {
    // Section Title
    class: 'font-extrabold text-sm leading-snug text-left',
    style: {
      fontFamily: font.raleway
    }
  },
  fft: {
    // Form Field Title
    class: 'font-extrabold text-white text-center',
    style: {
      fontFamily: font.raleway,
      lineHeight: 1.125
    }
  },
  ct: {
    // Category Titles
    class: 'text-brand-600 text-xs md:text-sm',
    style: {
      fontFamily: font.dmsans,
      lineHeight: 1.2
    }
  },
  cd: {
    // Countdown
    class: 'text-gray-600 text-sm md:text-sm font-bold',
    style: {
      fontFamily: font.dmsans,
      lineHeight: 1.2
    }
  },
  lt: {
    // Screen Title
    class:
      'font-normal text-2xl leading-none text-left text-brand-500 sm:text-4.5xl',
    style: {
      fontFamily: font.dmserif
    }
  }
};

const Text: React.FC<PropTypes> = ({ children, type, className, style }) => {
  return (
    <div
      className={classNames(types[type].class, className)}
      style={{ ...types[type].style, ...style }}
    >
      {children}
    </div>
  );
};

export default Text;
