export default `# Termin
13.12.2020 – 18.12.2020
Dauer 0.5-1 Tag

# Location
Berlin

# Kunde
ACME Corporation

# Art
Fotoshooting

# Fotograf
Hanna Grün

# Gagen und Buyout
Gage 0,5 Tag:\t\t850,-€
Gage 1 Tag:\t\t\t1200,-€
Buyout:\t\t\t\t3.000,-€

# Nutzungsrechte
Alle Medien, Deutschland, 3 Jahre

# Buyoutoption
Nutzung weitere 2 Jahre (nach Ablauf der 3 Jahre) 1200,-€

# Reisekosten
falls nicht ab Berlin, werden 120,-€ Reisekostenzuschuss bezahlt`;
