import { useState } from "react";
import uuid from "uuid";
import usePersist from "./persist";
import useSubmit from "./submit";
import useFileUpload from "./upload-files";
import qs from "qs";
import * as R from "ramda";

export default (flow: any, url?: any, initialData?: any, options: any = {}) => {
  const { resetInitialStack } = options;

  const stateIdentifier = flow.id + "_state";
  const initialState = JSON.parse(
    localStorage.getItem(stateIdentifier) || "{}"
  );
  let userAgent;
  if (!localStorage.getItem("userAgent")) {
    userAgent = { id: initialState.submissionId || uuid() };
    localStorage.setItem("userAgent", JSON.stringify(userAgent));
  } else {
    userAgent = JSON.parse(localStorage.getItem("userAgent")!);
  }
  if (!localStorage.getItem(stateIdentifier)) {
    const query = qs.parse(window.location.search.substr(1));
    initialState.stack = [...flow.initialStack];
    initialState.userAgentId = userAgent.id;
    initialState.moduleLandingQuery = query;
    localStorage.setItem(stateIdentifier, JSON.stringify(initialState));
  }
  if (resetInitialStack) {
    initialState.stack = [...flow.initialStack];
  }
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState<any>({});
  const pushStack = (screen: any) => {
    setState((s: { stack: any[] }) => ({
      ...s,
      stack: s.stack.concat([screen]),
    }));
    setTimeout(() => window.scrollTo(0, 0), 300);
  };
  const popStack = () => {
    setState((s: { stack: string | any[] }) => ({
      ...s,
      stack: s.stack.slice(0, s.stack.length - 1),
    }));
    setTimeout(() => window.scrollTo(0, 0), 300);
  };
  const updateScreen = (newScreen: any, scrollUp?: boolean) => {
    setState((s: { stack: any[] }) => ({
      ...s,
      stack: s.stack.map((screen: any, index: any) =>
        index + 1 === s.stack.length ? newScreen : screen
      ),
    }));
    if (scrollUp) {
      setTimeout(() => window.scrollTo(0, 0), 300);
    }
  };
  const showModal = (name, options) =>
    setState((s) => ({
      ...s,
      stack: s.stack.map((screen, index) =>
        index + 1 === s.stack.length
          ? R.assoc(
              'options',
              {
                ...R.path(['options'], R.last(s.stack)),
                modalVisible: true,
                modalName: name,
                modalOptions: options,
              },
              R.last(s.stack)
            )
          : screen
      ),
    }));
  const setModalVisibility = (isVisible) =>
    setState((s) => ({
      ...s,
      stack: s.stack.map((screen, index) =>
        index + 1 === s.stack.length
          ? R.assoc(
              'options',
              {
                ...R.path(['options'], R.last(s.stack)),
                modalVisible: isVisible,
              },
              R.last(s.stack)
            )
          : screen
      ),
    }));
  const updateStack = (newStack, scrollUp?: boolean) => {
    setState((s) => ({
      ...s,
      stack: newStack,
    }));
    if (scrollUp) {
      setTimeout(() => window.scrollTo(0, 0), 300);
    }
  };
  usePersist(stateIdentifier, state);
  const { uploadQueue } = useFileUpload(state, setState);
  const { submit, sendAction } = useSubmit(
    flow,
    state,
    loading,
    setLoading,
    submitted,
    setSubmitted,
    uploadQueue
  );

  return {
    state,
    setState,
    data,
    setData,
    sendAction,
    pushStack,
    popStack,
    showModal,
    setModalVisibility,
    updateScreen,
    loading,
    setLoading,
    submitted,
    submit,
    uploadQueue,
    updateStack,
  };
};
