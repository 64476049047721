// @ts-ignore-file
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as Sentry from "@sentry/browser";
// @ts-ignore
import config from "./config";
import "./styles/custom.css";
import "./index.css";
const addCss = (src: any) => {
  var link = document.createElement("link");
  link.rel = "stylesheet";
  link.type = "text/css";
  link.href = src;
  link.media = "all";
  document.head.appendChild(link);
};

addCss(
  "https://fonts.googleapis.com/css?family=DM+Sans:400,500,700|DM+Serif+Display|Raleway:800&display=swap"
);

const MODULE = process.env.REACT_APP_MODULE as string;

export interface ProcessEnv {
  [key: string]: string | undefined | typeof config;
}

let env = process.env["NODE_ENV"];

// @ts-ignore-start
const mountPoint =
  env === "production"
    ? // @ts-ignore
      config.flows[MODULE].mountPoint
    : "root";

// @ts-ignore-end

const mountDiv = document.createElement("div");
mountDiv.id = mountPoint;
document.body.appendChild(mountDiv);

var list = document.getElementsByTagName("link");
for (var i = 0; i < list.length; i++) {
  if (list[i].href.includes("webflow") && list[i].href.includes("css")) {
    list[i].remove();
  }
}

if (document.querySelector("meta[name='viewport']")) {
  (document.querySelector("meta[name='viewport']") as any).remove();
}
var meta = document.createElement("meta");
meta.name = "viewport";
meta.content = "width=device-width,initial-scale=1,user-scalable=no";
document.head.appendChild(meta);

if (window && !window.location.hostname.includes("localhost")) {
  Sentry.init({
    dsn: "https://b43d36474e6140838c2f3b421f8f5c40@sentry.io/1804223",
  });
}

ReactDOM.render(<App />, document.getElementById(mountPoint));
