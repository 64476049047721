import React, { useEffect } from "react";
import classNames from "classnames";
import Icon from "../icon-2";
import { useMinWidth, useDimensions } from "../../utils";

interface PropTypes {
  children: React.ReactNode;
  visible: boolean;
  setVisible: any;
  style?: any;
  preventExit?: boolean;
  modalClassName?: string;
}
const Modal: React.FC<PropTypes> = ({
  children,
  visible,
  setVisible,
  preventExit,
  style,
  modalClassName,
}) => {
  const isDesktop = useMinWidth(550);
  const [w, h] = useDimensions();
  useEffect(() => {
    const onClick = (e: any) => {
      if (preventExit) {
        return;
      }
      e.preventDefault();

      const path = e.path || e.composedPath();
      if (
        !visible ||
        path.some(
          (el: any) =>
            typeof el.className === "string" &&
            el.className.includes("modalContainer")
        )
      ) {
        return;
      }
      setVisible(false);
    };
    document.addEventListener("mouseup", onClick);
    return () => {
      document.removeEventListener("mouseup", onClick);
    };
  }, [visible, preventExit, setVisible]);
  return (
    <div
      className={classNames(
        "fixed top-0 flex justify-center items-center",
        visible ? "opacity-100" : "opacity-0 pointer-events-none"
      )}
      style={{
        zIndex: 70,
        width: w,
        height: h,
        backgroundColor: "rgba(0,0,0,0.8)",
        transition: visible ? "opacity 250ms" : "opacity 250ms",
      }}
    >
      <div
        className={classNames(
          "modalContainer bg-white cursor-default",
          visible ? "opacity-100" : "opacity-0",
          modalClassName
        )}
        style={{
          borderRadius: 22,
          minWidth: "18rem",
          paddingTop: "1.9rem",
          paddingBottom: "1.9rem",
          maxWidth: isDesktop ? "28rem" : 0.93 * w,
          transition: visible
            ? "opacity 250ms, transform 250ms"
            : "opacity 250ms, transform 250ms",
          transform: visible ? "translate(0px,0px)" : "translate(0px,-100px)",
          ...style,
        }}
      >
        {!preventExit && (
          <button
            onClick={() => setVisible(false)}
            className="absolute interactiveLight focus:outline-none"
            style={{
              top: "1.2rem",
              left: "1.2rem",
            }}
          >
            <Icon
              name="close"
              className="text-gray-300"
              style={{
                width: "1rem",
                height: "1rem",
              }}
            />
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
