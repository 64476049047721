import React from "react";
import LogoWatermark from "../../components/logo-watermark";

interface PropTypes {
  text: string;
}

const DefaultPage: React.FC<PropTypes> = ({ text }) => {
  return (
    <div className="screenManager h-screen w-full flex justify-center content-center">
      <div className="p-4 ">
          <div>{text}
          {text.includes('Lade')
              && (<div className="spinner">
                  <div className="double-bounce1"></div>
                  <div className="double-bounce2"></div>
              </div>)}

          </div>
      </div>
      <footer className="listFooter">
        <div className="relative" style={{ width: 120 }}>
          <LogoWatermark relative />
        </div>

        <div className="flex justify-end">Tel: +49 (0) 40. 29 90 8-0</div>
        <div className="flex justify-end">
          Email: <a href="mailto:info@procast.de">info@procast.de</a>
        </div>
      </footer>
    </div>
  );
};

export default DefaultPage;
