import React, { useContext } from "react";
import config from "../../../config";
import useFlowState from "../../../hooks/flow-state";
import useFavorites from "./use-favorites";
import useSedcards from "./use-sedcards";
import useSlideroom from "./use-slideroom";
import qs from "qs";
import DefaultPage from "../../default";
import { NotificationsContext } from "../../../config/context/provider/NotificationsProvider";

export const ModuleContext = React.createContext({} as any);

export const ModuleProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const flowState = useFlowState(config.flows["slide-room"], null, null, {
    resetInitialStack: true,
  });
  const { notifications } = useContext(NotificationsContext);

  useSlideroom(flowState);
  useSedcards(flowState);
  // useSimilar(flowState);
  const favorites = useFavorites(flowState);

  if (!qs.parse(window.location.search.substr(1)).id) {
    return <DefaultPage text={"Sie haben kein Casting ausgewählt"} />;
  }

  if (notifications?.length) {
    return <DefaultPage text={notifications} />;
  }

  return (
    <ModuleContext.Provider
      value={{
        ...flowState,
        ...favorites,
      }}
    >
      {children}
    </ModuleContext.Provider>
  );
};

export const useModuleState = () => useContext(ModuleContext);
