import { Dispatch, useEffect } from "react";
import axios from "axios";
import last from "ramda/src/last";
import queue from "async/queue";
import axiosRetry from "axios-retry";
import config from "../../config";

axiosRetry(axios, { retries: 3 });

const uploadFile = async (
  userAgentId: any,
  optionId: any,
  file: { name: string },
  onUploadProgress: () => void
) => {
  const res = await fetch(
      config.flows["slide-room"].apiBaseUrl + `/getUploadUrl?submissionId=${optionId}_${userAgentId}&fileType=${last(file.name.toLowerCase().split("."))}`
  );
  const {
    body: { put: putUrl, url: getUrl },
  } = await res.json();
  await axios.put(putUrl, file, {
    onUploadProgress,
  });
  return getUrl;
};

const q = queue(function (
  { state, setState, file, key }: any,
  callback: (arg0: any) => void
) {
  return uploadFile(state.userAgentId, state.optionId, file, () => {}).then((url) =>
    setState((previous: any) => {
      const newState = { ...previous };
      newState[key].value[newState[key].value.indexOf(file)] = url;
      callback(null);
      return newState;
    })
  );
},
1);

const inProgressFiles: any = [];
const useFileUpload = (state: any, setState: Dispatch<any>) => {
  useEffect(() => {
    const fileHolders = Object.keys(state).filter(
      (key) =>
        state[key] !== null &&
        state[key] !== undefined &&
        state[key].type === "files"
    );
    fileHolders.forEach((key) =>
      state[key].value.forEach((file: any) => {
        if (typeof file === "string" || inProgressFiles.includes(file)) {
          return;
        }
        inProgressFiles.push(file);
        q.push({ file, key, setState, state }, () => {});
      })
    );
  }, [state, setState]);
  return { uploadQueue: q };
};

export default useFileUpload;
