import React from 'react';
import './env';
import AvailabilityRequest from './modules/availability-request';
import SlideRoom from './modules/slide-room';
import DefaultPage from "./modules/default";
import { ContextProvider } from "./config/context/state";
const MODULE = process.env.REACT_APP_MODULE;

function App() {
  if (MODULE === 'availability-request') {
    return <ContextProvider><AvailabilityRequest /></ContextProvider>;
  }
  if (MODULE === 'slide-room') {
    return <ContextProvider><SlideRoom /></ContextProvider>;
  }
  return <DefaultPage text="Sie haben kein Modul ausgewählt" />;
}

export default App;
