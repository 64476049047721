import React, { ReactNode } from 'react';
import { ModuleProvider } from './state';
import Module from './module';
import Sprite from '../../components/icon-2/sprite';
import createMatomoInstance from '../../utils/createMatomoInstance';
import { MatomoInstance } from '@datapunt/matomo-tracker-react/lib/types';

const instance = createMatomoInstance(3);

interface MatomoProps {
  value?: MatomoInstance;
  children?: React.ReactNode;
}

const MatomoProvider: React.FC<MatomoProps> = React.Fragment;

const Wrapper: React.FC<MatomoProps> = () => {
  return (
    <MatomoProvider value={instance}>
      <ModuleProvider>
        <Sprite />
        <Module />
      </ModuleProvider>
    </MatomoProvider>
  );
};

export default Wrapper;
