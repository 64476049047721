import React from 'react';

interface PropTypes {
  children: React.ReactNode;
  className?: string;
  style?: any;
}

const Container: React.FC<PropTypes> = ({ children, className, style }) => {
  return (
    <div
      style={style}
      className={className}
    >
      {children}
    </div>
  );
};

export default Container;
