import * as React from 'react';
import { createContext, useState } from 'react';

export const NotificationsContext = createContext<
    any[] | any
    >(null);

export function NotificationsProvider(props) {
    const [notifications, setNotifications] = useState([]);

    return (
        <NotificationsContext.Provider
            value={{ notifications, setNotifications }}
        >
            {props.children}
        </NotificationsContext.Provider>
    );
}
