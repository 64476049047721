import React from 'react';
import classNames from 'classnames';

import Text from '../text';

interface PropTypes {
  onClick: (x: any) => void;
  children: React.ReactNode;
  errorMessage?: string;
  bg?: string;
  id?: string;
  style?: any;
  wrapperStyle?: any;
  modal?: boolean;
  description?: React.ReactNode | string;
}

const Button: React.FC<PropTypes> = ({
  onClick,
  children,
  errorMessage,
  id,
  style,
  wrapperStyle,
  modal,
  description
}) => {
  return (
    <div
      className={classNames(
        'fixed md:absolute bottom-0 z-50 left-0 w-full pt-4 pb-4 md:pb-8 border-t border-brand-300 md:border-none z-10 flex justify-center flex-col items-center bg-white'
      )}
      style={wrapperStyle}
    >
      {!!errorMessage && (
        <Text type="dtc" className="mb-2">
          {errorMessage}
        </Text>
      )}
      <div
        className="w-full lg:w-3/4 flex items-center justify-center"
        style={{
          maxWidth: modal ? undefined : 650,
          width: modal ? '100%' : undefined
        }}
      >
        <button
          id={id}
          onClick={onClick}
          className={classNames(
            'bg-brand-700 w-full  text-white h-12 focus:outline-none font-bold tracking-wider interactive',
            'ea-pricecalculator-primary-cta',
            modal ? 'mx-5 xs:mx-10' : 'md:mx-32 mx-10'
          )}
          style={style}
        >
          <Text type="cta">{children}</Text>
        </button>
      </div>
      <Text
        type="dtc"
        className={classNames(
          'mt-2',
          modal ? 'mx-5 xs:mx-10' : 'md:mx-32 mx-10'
        )}
        style={{
          maxWidth: modal ? undefined : 650,
          width: modal ? '100%' : undefined
        }}
      >
        {description}
      </Text>
    </div>
  );
};
export default Button;
