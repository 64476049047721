import uuid from 'uuid';

function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(
      match,
      p1
    ) {
      //@ts-ignore
      return String.fromCharCode('0x' + p1);
    })
  );
}

export default (state, url, action, args) => {
  const submittedState = {
    ...state,
    actionId: uuid(),
    createdAt: new Date().toISOString(),
    action,
    arguments: args,
    module: process.env.REACT_APP_MODULE,
    host:
      window && window.location && window.location.hostname
        ? window.location.hostname
        : null,
    path:
      window && window.location && window.location.pathname
        ? window.location.pathname
        : null,
    search:
      window && window.location && window.location.search
        ? window.location.search
        : null,
    userAgent: navigator && navigator.userAgent ? navigator.userAgent : null,
    innerWidth: window && window.innerWidth ? window.innerWidth : null,
    innerHeight: window && window.innerHeight ? window.innerHeight : null,
    referrer: document && document.referrer ? document.referrer : null,
    language: navigator && navigator.language ? navigator.language : null,
    timezone: new Date().getTimezoneOffset()
  };
  return window
    .fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify({
        data: b64EncodeUnicode(JSON.stringify(submittedState))
      })
    })
    .catch(e => {});
};
