import React, { useEffect, useState } from "react";
import Screen from "../../../../../components/screen";
import { useModuleState } from "../../../state";

import Text from "../../../../../components/text";
import QuickFacts from "../../../../../components/quick-facts";
import Action from "../../../../../components/action";
import qs from "qs";
import classNames from "classnames";
import exampleText from "./example";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { parseFacts, copyToClipboard } from "../../../../../utils";

export default () => {
  const {
    data: {
      Optionsbeschreibung: OptionsbeschreibungString,
      Antworten: answersInput,
      optionState,
      Status,
      BuchenStatus,
      BemerkungAvailability,
    },
    pushStack,
  } = useModuleState();

  const { trackPageView, trackEvent } = useMatomo();
  useEffect(() => {
    trackPageView({ documentTitle: "Verfuegbarkeit Anfrage" });
  }, [trackPageView]);

  const { erstellen: createModeParameter } = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const createMode = typeof createModeParameter !== "undefined";
  const [factsStringInput, setFactsStringInput] = useState("");
  const factsInput = createMode ? factsStringInput : OptionsbeschreibungString;
  if (
    typeof factsInput === "undefined" &&
    ![
      "1. Option",
      "1. Option(Siehe Bemerkung!)",
      "Absage",
      "keine Zeit",
      "kein Interesse",
    ].includes(Status)
  ) {
    return null;
  }
  const facts = parseFacts(factsInput);
  const answers = parseFacts(answersInput);
  const availabilities = BemerkungAvailability
    ? BemerkungAvailability.replaceAll("VIELLEICHT", "VIELLEICHT|")
        .replaceAll("NEIN", "NEIN|")
        .replaceAll("|,", "|")
        .split("|")
    : "";

  return (
    <Screen containerStyle={{ paddingBottom: 0 }}>
      <Text type="st" className="mt-10">
        {createMode
          ? "Anfrage erstellen"
          : BuchenStatus
          ? "Du bist dabei!"
          : "Deine Anfrage"}
      </Text>
      {createMode && (
        <div className="relative">
          <textarea
            autoFocus
            className={
              "focus:outline-none resize-none pt-3 pb-12 pl-4 w-full text-brand-500 my-2 pr-4 mt-8"
            }
            placeholder={"Anfragetext eintragen"}
            value={factsStringInput}
            onChange={(e) => {
              e.persist();
              setFactsStringInput(e.target.value);
            }}
            rows={15}
            style={{
              WebkitAppearance: "none",
              fontFamily: "'DM Sans', sans-serif",
              boxShadow: "rgba(184,184,184, 1) 0px 0px 10px 0px",
            }}
          />
          <div className="absolute bottom-0 right-0 mb-5 mr-1">
            <button
              className="text-xs bg-brand-500 text-white py-1 px-3 mr-2 interactive focus:outline-none"
              onClick={() => setFactsStringInput(exampleText)}
            >
              Beispiel anzeigen
            </button>
            <button
              className="text-xs bg-brand-700 text-white py-1 px-3 interactive focus:outline-none"
              onClick={() => copyToClipboard(factsStringInput)}
            >
              Kopieren
            </button>
          </div>
        </div>
      )}
      {createMode && !!facts.length && (
        <Text type="sst" style={{ paddingBottom: 0 }}>
          Vorschau der Anfrage
        </Text>
      )}
      {!!facts.length && (
        <QuickFacts
          data={facts}
          className={classNames(
            "md:bg-gray-100 md:p-3",
            BuchenStatus ? "mb-20" : "",
            createMode ? "mt-3" : "mt-16"
          )}
        />
      )}
      {optionState === "second" && (
        <Text type="sst" className="mt-10">
          Aktueller Status: Du bist eventuell verfügbar.
        </Text>
      )}

      {BuchenStatus && answers && answers.length && (
        <QuickFacts
          data={answers}
          className={classNames("md:bg-gray-100 md:p-3 mt-2 mb-4")}
        />
      )}

      {BuchenStatus && !!BemerkungAvailability.length && (
        <div
          style={{ fontFamily: "'DM Sans', sans-serif" }}
          className={classNames(
            "text-sm font-bold w-full md:bg-gray-100 md:p-3 mt-2 mb-4"
          )}
        >
          <table className="w-full">
            <tbody className="w-full">
              <tr>
                <td
                  className="text-brand-500 pr-5 align-top"
                  style={{ width: "40%" }}
                >
                  Bis auf
                  <br />
                  vorgemerkte Termin-Einschränkungen
                  <br />
                </td>
                <td className="text-brand-700 align-top break-words">
                  {availabilities.map((item: any) => (
                    <span key={item}>
                      {item}
                      <br />
                    </span>
                  ))}
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {!BuchenStatus && (
        <Action
          inline
          buttons={
            createMode
              ? [
                  {
                    onClick: () =>
                      pushStack({
                        type: "ViewQuestions",
                      }),
                    secondary: true,
                    children: "Ist Interessant",
                  },
                ]
              : [
                  {
                    onClick: () => {
                      trackEvent({
                        category: "verfuegbarkeit-click",
                        action: "Anfrage interessant",
                      });
                      pushStack({
                        type: "ViewQuestions",
                      });
                    },
                    children: "Ist Interessant",
                  },

                  {
                    onClick: () => {
                      trackEvent({
                        category: "verfuegbarkeit-click",
                        action: "Anfrage uninteressant",
                      });
                      pushStack({ type: "ViewDecline" });
                    },
                    children: "Nein Danke!",
                  },
                ]
          }
        />
      )}
    </Screen>
  );
};
