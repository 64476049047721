import React from 'react';
import classNames from 'classnames';
import Logo from "../../img/procast-logo-small.png";
interface PropTypes {
  bottom?: boolean;
  relative?: boolean;
}

const Component: React.FC<PropTypes> = ({ bottom, relative }) => (
  <img
    src={Logo}
    className={classNames(
      'w-32 block', {
        'absolute bottom-0 left-0 mb-11 ml-11': bottom && !relative,
        'absolute top-0 left-0 mt-11 ml-11': !bottom && !relative,
        'relative mt-0 ml-0': relative && !bottom,
    }
    )}
    alt="Procast Logo"
  />
);
export default Component;
