import React from 'react';
import ViewRequest from './view-request';
import ViewQuestions from './view-questions';
import ViewMessage from './view-message';
import ViewDecline from './view-decline';
import ViewEnterOtherReason from './view-enter-other-reason';
import ViewSelectFollowupDate from './view-select-followup-date';

export default {
  ViewRequest: {
    node: <ViewRequest />,
    options: { wrapperStyle: { maxWidth: 531 }, hideBackButton: true }
  },
  ViewQuestions: { node: <ViewQuestions /> },
  ViewDecline: { node: <ViewDecline /> },
  ViewEnterOtherReason: { node: <ViewEnterOtherReason /> },
  ViewSelectFollowupDate: { node: <ViewSelectFollowupDate /> },
  ViewMessage: { node: <ViewMessage />, options: { hideBackButton: true } }
};
