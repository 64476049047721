const commonDevEnv = {
  BASE_URL: 'https://wedding-4b9214.webflow.io/'
};

const commonProdEnv = {
  BASE_URL: 'https://www.mein-traum-hochzeitsfotograf.de/'
};

(window as any).env = {
  ...(window as any).env,
  ...(window.location.hostname === 'www.mein-traum-hochzeitsfotograf.de'
    ? commonProdEnv
    : commonDevEnv)
};

export default (window as any).env;
