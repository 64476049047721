import React, {useEffect, useState} from "react";
import Screen from "../../../../../components/screen";
import { useModuleState } from "../../../state";
import Text from "../../../../../components/text";
import Icon from "../../../../../components/icon-2";
import Action from "../../../../../components/action";
import LogoWatermark from "../../../../../components/logo-watermark";
import { useMinWidth } from "../../../../../utils";
import declineOptions from "./decline-options";
import {useMatomo} from "@datapunt/matomo-tracker-react";

export default () => {
  const { pushStack, submitAvailabilityFeedback } = useModuleState();
  const desktop = useMinWidth(768);
  const [selection, setSelection] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState("");
    const { trackPageView, trackEvent } = useMatomo();
    useEffect(() => {
        trackPageView({documentTitle: 'Verfuegbarkeit Absage bestätigen',})
    }, [trackPageView]);

  return (
    <Screen containerStyle={{ paddingBottom: desktop ? 0 : undefined }}>
      <LogoWatermark bottom />

      <Text type="st" className="md:px-8">
        Was ist der Grund für deine Absage?
      </Text>
      <Text
        type="sst"
        style={{
          paddingLeft: desktop ? "8rem" : undefined,
          paddingRight: desktop ? "8rem" : undefined,
        }}
      >
        Wähle den Grund für deine Absage.
      </Text>
      <div className="flex flex-col justify-center items-center md:px-8 pt-4">
        {declineOptions.map((reason, index) => {
          const checked = selection === reason;
          return (
            <button
              key={index}
              className="focus:outline-none my-2 px-4 bg-gray-100 md:bg-brand-500 text-brand-500 md:text-white w-full flex items-center interactive"
              style={{ maxWidth: 373, height: "5.25rem" }}
              onClick={() => setSelection(reason)}
            >
              {!checked ? (
                <div className="rounded-full border-2 border-brand-500 md:border-white h-10 w-10 flex-shrink-0" />
              ) : (
                <Icon
                  name="checkmark"
                  className="w-10 h-10 bg-gray-100 md:bg-brand-500 flex-shrink-0"
                  style={{
                    "--bgColor": desktop ? "#8BABAD" : "white",
                  }}
                />
              )}
              <div
                className="text-left flex-grow text-xl md:text-2xl font-semibold tracking-wide ml-4"
                style={{ fontFamily: "'DM Sans', sans-serif" }}
              >
                {reason.label}
              </div>
            </button>
          );
        })}
      </div>
      <Action
        inline
        errorMessage={errorMessage}
        buttons={[
          {
            onClick: async () => {
              if (!selection) {
                return setErrorMessage(
                  "Bitte wähle einen Grund um fortzufahren"
                );
              }

                    trackEvent({ category: 'verfuegbarkeit-click', action: "Absage bestätigen" });
              if (selection.freeText) {
                return pushStack({
                  type: "ViewEnterOtherReason",
                  options: { declineOptions, selection },
                });
              }
              await submitAvailabilityFeedback({
                type: "DECLINED",
                arguments: { declineOptions, selection },
              });
              pushStack({
                type: "ViewMessage",
                options: { variant: "ACTOR_DECLINED" },
              });
            },
            children: "Absage bestätigen",
          },
        ]}
      />
    </Screen>
  );
};
