import React, { useEffect, useState } from "react";
import Screen from "../../../../../components/screen";
import { useModuleState } from "../../../state";
import Text from "../../../../../components/text";

import { getScreenOptions, parseFacts } from "../../../../../utils";
import QuickFacts from "../../../../../components/quick-facts";
import classNames from "classnames";
import qs from "qs";
import { useMatomo } from "@datapunt/matomo-tracker-react";

export default () => {
  const {
    data: {
      Optionsbeschreibung: OptionsbeschreibungString,
      Antworten: answersInput,
      BemerkungAvailability,
      Status,
    },
    state,
    pushStack,
  } = useModuleState();
  const [{ variant }] = useState(getScreenOptions(state));

  const { trackPageView, trackEvent } = useMatomo();
  useEffect(() => {
    trackPageView({ documentTitle: "Verfuegbarkeit Zusammenfassung" });
  }, [trackPageView]);

  const { erstellen: createModeParameter } = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const createMode = typeof createModeParameter !== "undefined";
  const factsStringInput = "";
  const factsInput = createMode ? factsStringInput : OptionsbeschreibungString;
  if (
    typeof factsInput === "undefined" &&
    ![
      "1. Option",
      "1. Option(Siehe Bemerkung!)",
      "Absage",
      "keine Zeit",
      "kein Interesse",
    ].includes(Status)
  ) {
    return null;
  }

  const facts = parseFacts(factsInput);

  const answers = parseFacts(answersInput);

  const availabilities = BemerkungAvailability
    ? BemerkungAvailability.replaceAll("VIELLEICHT", "VIELLEICHT|")
        .replaceAll("NEIN", "NEIN|")
        .replaceAll("|,", "|")
        .split("|")
    : "";

  return (
    <Screen
      containerStyle={{
        paddingBottom: 0,
        marginTop: 0,
        paddingTop: 0,
      }}
    >
      <div
        className="flex flex-col justify-center"
        style={{ height: "100%", overflow: "auto" }}
      >
        <Text type="st" className="mt-4">
          Zusammenfassung
        </Text>

        <Text type="st2" className="mt-8">
          Das Casting
        </Text>
        {!!facts.length && (
          <QuickFacts
            data={facts}
            isAnswer
            className={classNames("md:bg-gray-100 md:p-3 mt-2 mb-4")}
          />
        )}

        <Text type="st2" className="mt-8">
          Deine Antworten
        </Text>
        {answers && answers.length && (
          <QuickFacts
            data={answers}
            className={classNames("md:bg-gray-100 md:p-3 mt-2 mb-4")}
          />
        )}
        {!!BemerkungAvailability.length && (
          <div
            style={{ fontFamily: "'DM Sans', sans-serif" }}
            className={classNames(
              "text-sm font-bold w-full md:bg-gray-100 md:p-3 mt-2 mb-4"
            )}
          >
            <table className="w-full">
              <tbody className="w-full">
                <tr>
                  <td
                    className="text-brand-500 pr-5 align-top"
                    style={{ width: "40%" }}
                  >
                    Bis auf
                    <br />
                    vorgemerkte Termin-Einschränkungen
                    <br />
                  </td>
                  <td className="text-brand-700 align-top break-words">
                    {availabilities.map((item: any) => (
                      <span key={item}>
                        {item}
                        <br />
                      </span>
                    ))}
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        <Text type="st2" className="mt-8">
          {variant === "ACTOR_MAYBE" ? "Sag Bescheid!" : "Vielen Dank!"}
        </Text>
        <Text type="sst" className="md:px-32">
          {variant === "ACTOR_CONFIRMED" ? (
            <span>
              <button
                onClick={() => pushStack({ type: "ViewRequest" })}
                className="inline underline focus:outline-none font-semibold hidden"
              >
                hier
              </button>
              Wir leiten deine Rückmeldung an den Kunden weiter und melden uns,
              sobald wir ein Feedback haben.
            </span>
          ) : variant === "ACTOR_MAYBE" ? (
            <span>
              Bitte informiere uns{" "}
              <button
                onClick={() => {
                  trackEvent({
                    category: "verfuegbarkeit-click",
                    action: "Änderung Verfügbarkeit Termin",
                  });
                  pushStack({ type: "ViewRequest" });
                }}
                className="inline underline focus:outline-none font-semibold"
              >
                hier
              </button>{" "}
              oder per Email, sobald du weißt, ob du am genannten Termin
              verfügbar bist.
            </span>
          ) : (
            <button
              onClick={() => pushStack({ type: "ViewRequest" })}
              className="inline underline focus:outline-none font-semibold hidden"
            >
              hier
            </button>
          )}
        </Text>
      </div>
    </Screen>
  );
};
