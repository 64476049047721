import JSZip from "jszip";
import JSZipUtils from "./jszip-utils.min";
import saveAs from "file-saver";

let Promise = window.Promise;
if (!Promise) {
    // @ts-ignore
    Promise = JSZip.external.Promise;
}

/**
 * Fetch the content and return the associated promise.
 * @param {String} url the url of the content to fetch.
 * @return {Promise} the promise containing the data.
 */
export function urlToPromise(url) {
    return new Promise(function(resolve, reject) {
        JSZipUtils.getBinaryContent(url, function (err, data) {

            if(err) {
                resolve("Die Datei konnte leider nicht gefunden werden!");
            } else {
                resolve(data);
            }
        });
    });
}


export const createDownloads =(PDFlist, OptionID, Title) => {
    if(!JSZip.support.blob) {
        alert("Der Download aller ausgewählten PDFs ist technisch nur in bestimmten Browsern möglich. " +
            "Bitte verwenden Sie einen anderen Browser!");
        return;
    }
    const zip = new JSZip();
    // find every checked item
    PDFlist.forEach((item: any, index) => {
        // @ts-ignore
        zip.file(`Darsteller-${OptionID}–${Title}-${index + 1}.pdf`, urlToPromise(item), {binary: true});


    });
    // when everything has been downloaded, we can trigger the dl
    zip.generateAsync( { type:"blob" } ).then( function( blob ) {
        saveAs( blob, `Casting-${OptionID}–${Title}.zip` );
    } );

}
export const createMultiDownloads =(PDFlist, OptionID, Title, start, chunk) => {
    if(!JSZip.support.blob) {
        alert("Der Download aller ausgewählten PDFs ist technisch nur in bestimmten Browsern möglich. " +
            "Bitte verwenden Sie einen anderen Browser!");
        return;
    }

    const zip = new JSZip();
    // find every checked item
    PDFlist.forEach((item: any) => {
        item.Sedcards.forEach((download: any, index) => {
            // @ts-ignore
            zip.folder(`Darsteller-${item.IDNummer}-${item.Name}`).file(`Darsteller-${item.IDNummer}-${item.Name}-${index + 1}.pdf`, urlToPromise(download.Download), {binary:true});
       })
    });

    // when everything has been downloaded, we can trigger the dl
    zip.generateAsync( { type:"blob" } ).then( function( blob ) {
        saveAs( blob, `Casting-${OptionID}–${Title}__${start + 1 }-${start + chunk}.zip` );
    } );


}
