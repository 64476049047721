import React, { useContext } from "react";
import config from "../../../config";
import useFlowState from "../../../hooks/flow-state";
import { post } from "../../../utils/request";
import useRemoteState from "./use-remote-state";

export const ModuleContext = React.createContext({} as any);

const getFreeText = (type, args) => {
  let freeText: any = [];
  if (type === "FIRST_OPTION" || type === "SECOND_OPTION") {
    const { questions, selection, followupDate } = args;
    questions.forEach((q, i) => {
      freeText.push("# " + q.plainText);
      if (q.type === "dropdown") {
        freeText.push(q.options[+selection[i].index - 1].label);
        if (selection[i].textInput) {
          freeText.push("Bemerkung: " + selection[i].textInput);
        }
      } else if (q.type === "upload") {
        args.selection[String(i)].forEach((url) => {
          freeText.push(url);
        });
      }
      freeText.push("");
    });
    if (followupDate) {
      freeText.push(
        "# Wann kannst du voraussichtlich eine finale Rückmeldung geben?"
      );
      freeText.push(
        followupDate[0] + ". " + followupDate[1] + " " + followupDate[2]
      );
    }
  } else if (type === "DECLINED") {
    const { selection, otherReason } = args;
    freeText.push("# Was ist der Grund für deine Absage?");
    if (otherReason) {
      freeText.push("" + otherReason);
    } else {
      freeText.push("" + selection.label);
    }
  }
  return freeText.join("\r");
};

export const ModuleProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const flowState = useFlowState(
    config.flows["availability-request"],
    null,
    null,
    { resetInitialStack: true }
  );
  useRemoteState(flowState);

  const submitAvailabilityFeedback = async (res) => {
    const freeText = getFreeText(res.type, res.arguments);
    // res.type === "FIRST_OPTION"
    // res.type === "SECOND_OPTION"
    // res.type === "DECLINED"

    // console.log('submitAvailabilityFeedback', res);
    flowState.setData((d) => {
      const parameters: any = {
        type:
          res.type === "DECLINED"
            ? "Absage"
            : res.type === "FIRST_OPTION"
            ? "Bereit"
            : "OnHold",
        actorId: d.IDNummer,
        OptionPosID: d.OptionPosID,
        optionId: String(d.OptionId),
      };
      if (res.type === "DECLINED") {
        parameters.absageStatus = res.arguments.selection.absageStatus;
      }
      if (freeText) {
        parameters.antworten = freeText;
      }
      if (res.arguments.dates) {
        parameters.BemerkungAvailability = res.arguments.dates;
      }

      if (res.type === "SECOND_OPTION") {
        parameters.type= "OnHold";
        parameters.optionState = "second";
      }


      post("set-optionactor-status", parameters).then((res) => {
        // console.log("set-optionactor-status result", res);
        flowState.setData((x) => ({ ...x, ...res,  }));
      });
      // console.log('after submitAvailabilityFeedback', d);
      return d;
    });

    /*if (res.type === "SECOND_OPTION") {
      console.log('submitAvailabilityFeedback befor second', res)
      // flowState.setData((d) => ({ ...d, optionState: "second" }));
    }*/
  };

  return (
    <ModuleContext.Provider
      value={{
        ...flowState,
        submitAvailabilityFeedback,
      }}
    >
      {children}
    </ModuleContext.Provider>
  );
};

export const useModuleState = () => useContext(ModuleContext);
