import * as React from 'react';
import {
	NotificationsProvider,
} from './provider/NotificationsProvider';

function ProviderComposer({ contexts, children }) {
	return contexts.reduceRight(
		(children, parent) =>
			React.cloneElement(parent, {
				children: children
			}),
		children
	);
}

function ContextProvider({ children }) {
	return (
		<ProviderComposer
			contexts={[

				<NotificationsProvider />,

			]}
		>
			{children}
		</ProviderComposer>
	);
}

export { ContextProvider };
